import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
// import FemaleIcon from "@mui/icons-material/Female";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AspectRatio from "@mui/joy/AspectRatio";
import horse from "../../../assets/images/horse.png";
import { viewbreed } from "../../../apis";
import { Button } from "@mui/material";
import classes from "./BreedListDetails.module.css";
import dummyHorse from "../../../assets/images/tempHorse.jpg";
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
// Example items, to simulate fetching from another resources.
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

function Items({ currentItems, query, gender, resetSearchQuery }) {
  const navigate = useNavigate();
  const breedId = useParams().breedId;
  const [HORSE_DATA, setHorseData] = useState([]);
  const [filly, setFilly] = useState(false);
  const [colt, setColt] = useState(false);
  const [stallion, setStallion] = useState(false);
  const [mare, setMare] = useState(false);
  const [all, setAll] = useState(true);

  const breedDetailNavigateHandler = (name) => {
    navigate(`${name}`);
  };

  const handleGender = (gender) => 
  {
    if(gender == 'filly')
    {
      setFilly(true);
      setColt(false);
      setStallion(false);
      setMare(false);
      setAll(false);
    }
    else if(gender == 'colt')
    {
      setFilly(false);
      setColt(true);
      setStallion(false);
      setMare(false);
      setAll(false);
    }
    else if(gender == 'stallion')
    {
      setFilly(false);
      setColt(false);
      setStallion(true);
      setMare(false);
      setAll(false);
    }
    else if(gender == 'mare')
    {
      setFilly(false);
      setColt(false);
      setStallion(false);
      setMare(true);
      setAll(false);
    }
    else
    {
      setFilly(false);
      setColt(false);
      setStallion(false);
      setMare(false);
      setAll(true);
    }
  }

  return (
    <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {query !== "" ? (
          <Button
            variant="primary"
            style={{
              color: "#0f2d25",
              margin: 0,
            }}
            onClick={resetSearchQuery}
          >
            View All Horses
          </Button>
          ) :
          (
            <></>
          )}
        
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack>
            <Switch
              checked = {all}
              onChange={() => handleGender('all')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>All Horses</Typography>
          </Stack>
          <Stack>
            <Switch
              checked = {filly}
              onChange={() => handleGender('filly')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>Fillies</Typography>
          </Stack>
          <Stack>
            <Switch
              checked = {colt}
              onChange={() => handleGender('colt')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>Colts</Typography>
          </Stack>
          <Stack>
            <Switch
              checked = {stallion}
              onChange={() => handleGender('stallion')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>Stallions</Typography>
          </Stack>
          <Stack>
            <Switch
              checked = {mare}
              onChange={() => handleGender('mare')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>Mares</Typography>
          </Stack>
        </Stack>
        </div>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="center"
      >
        {currentItems &&
          currentItems.map((item) => (
            item.gender2 >= 4 && mare === true && item.gender === 'Female'?
            (
              <Grid item xs={12} sm={12} md={4} key={item.id}>
                <div
                  onClick={() => {
                    breedDetailNavigateHandler(item.friendly_URL);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                >
                  {/* <img
                  src={
                    item.imageUrl ||
                    "/static/media/temp-horse.cc8728a6d7a678026833.png"
                  }
                  alt={item.horse_name}
                /> */}
                  <AspectRatio
                    ratio="1"
                    sx={{
                      width: 150,
                      top: "-1rem",
                      marginRight: "-2rem",
                      zIndex: "1",
                      borderRadius: "100%",
                      overflow: "hidden",
                      "@media (max-width: 600px)": {
                        marginRight: "-0.1rem",
                        width: "50%",
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <img
                      src={
                        item.imageUrl ||
                        "/static/media/temp-horse.cc8728a6d7a678026833.png"
                      }
                      loading="lazy"
                      style={{
                        objectFit: "contain",
                        borderRadius: "100%",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </AspectRatio>
                  <Card
                    sx={{
                      width: "100%",
                      maxWidth: 370,
                      boxShadow: "lg",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "30px",
                      padding: "3px",
                      "@media (max-width: 600px)": {
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        padding: "1.2rem",
                        "@media (max-width: 600px)": {
                          flex: 2,
                          padding: "8px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Typography
                        level="title-lg"
                        id="card-description"
                        style={{
                          color: "#217a77",
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {item.horse_name} ||{" "}
                        {new Date(item?.birth_year).getFullYear()} ||{" "}
                        {item.gender == 'Male' ?
                          <MaleIcon
                            fontSize="inherit"
                            className={` ${classes.male}`}
                          />
                          :
                          <FemaleIcon
                            fontSize="inherit"
                            className={` ${classes.female}`}
                          />
                      }

                      </Typography>
                      <Typography
                        level="title-lg"
                        style={{
                          color: "#000000",
                          opacity: 0.9,
                          marginTop: "8px",
                        }}
                      >
                        <span className={classes.bold}>Owner:</span>{" "}
                        {item?.owner_name !== ""
                          ? item?.owner_name
                          : "Not Specified"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            )
            : item.gender2 < 4 && filly === true && item.gender === 'Female'?
            (
              <Grid item xs={12} sm={12} md={4} key={item.id}>
                <div
                  onClick={() => {
                    breedDetailNavigateHandler(item.friendly_URL);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                >
                  {/* <img
                  src={
                    item.imageUrl ||
                    "/static/media/temp-horse.cc8728a6d7a678026833.png"
                  }
                  alt={item.horse_name}
                /> */}
                  <AspectRatio
                    ratio="1"
                    sx={{
                      width: 150,
                      top: "-1rem",
                      marginRight: "-2rem",
                      zIndex: "1",
                      borderRadius: "100%",
                      overflow: "hidden",
                      "@media (max-width: 600px)": {
                        marginRight: "-0.1rem",
                        width: "50%",
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <img
                      src={
                        item.imageUrl ||
                        "/static/media/temp-horse.cc8728a6d7a678026833.png"
                      }
                      loading="lazy"
                      style={{
                        objectFit: "contain",
                        borderRadius: "100%",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </AspectRatio>
                  <Card
                    sx={{
                      width: "100%",
                      maxWidth: 370,
                      boxShadow: "lg",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "30px",
                      padding: "3px",
                      "@media (max-width: 600px)": {
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        padding: "1.2rem",
                        "@media (max-width: 600px)": {
                          flex: 2,
                          padding: "8px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Typography
                        level="title-lg"
                        id="card-description"
                        style={{
                          color: "#217a77",
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {item.horse_name} ||{" "}
                        {new Date(item?.birth_year).getFullYear()} ||{" "}
                        {item.gender == 'Male' ?
                          <MaleIcon
                            fontSize="inherit"
                            className={` ${classes.male}`}
                          />
                          :
                          <FemaleIcon
                            fontSize="inherit"
                            className={` ${classes.female}`}
                          />
                      }

                      </Typography>
                      <Typography
                        level="title-lg"
                        style={{
                          color: "#000000",
                          opacity: 0.9,
                          marginTop: "8px",
                        }}
                      >
                        <span className={classes.bold}>Owner:</span>{" "}
                        {item?.owner_name !== ""
                          ? item?.owner_name
                          : "Not Specified"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            )
            : item.gender2 >= 4 && stallion === true  && item.gender === 'Male'?
            (
              <Grid item xs={12} sm={12} md={4} key={item.id}>
                <div
                  onClick={() => {
                    breedDetailNavigateHandler(item.friendly_URL);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                >
                  <AspectRatio
                    ratio="1"
                    sx={{
                      width: 150,
                      top: "-1rem",
                      marginRight: "-2rem",
                      zIndex: "1",
                      borderRadius: "100%",
                      overflow: "hidden",
                      "@media (max-width: 600px)": {
                        marginRight: "-0.1rem",
                        width: "50%",
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <img
                      src={
                        item.imageUrl ||
                        "/static/media/temp-horse.cc8728a6d7a678026833.png"
                      }
                      loading="lazy"
                      style={{
                        objectFit: "contain",
                        borderRadius: "100%",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </AspectRatio>
                  <Card
                    sx={{
                      width: "100%",
                      maxWidth: 370,
                      boxShadow: "lg",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "30px",
                      padding: "3px",
                      "@media (max-width: 600px)": {
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        padding: "1.2rem",
                        "@media (max-width: 600px)": {
                          flex: 2,
                          padding: "8px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Typography
                        level="title-lg"
                        id="card-description"
                        style={{
                          color: "#217a77",
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {item.horse_name} ||{" "}
                        {new Date(item?.birth_year).getFullYear()} ||{" "}
                        {item.gender == 'Male' ?
                          <MaleIcon
                            fontSize="inherit"
                            className={` ${classes.male}`}
                          />
                          :
                          <FemaleIcon
                            fontSize="inherit"
                            className={` ${classes.female}`}
                          />
                      }

                      </Typography>
                      <Typography
                        level="title-lg"
                        style={{
                          color: "#000000",
                          opacity: 0.9,
                          marginTop: "8px",
                        }}
                      >
                        <span className={classes.bold}>Owner:</span>{" "}
                        {item?.owner_name !== ""
                          ? item?.owner_name
                          : "Not Specified"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            )
            : item.gender2 < 4 && colt == true && item.gender === 'Male'?
            (
              <Grid item xs={12} sm={12} md={4} key={item.id}>
                <div
                  onClick={() => {
                    breedDetailNavigateHandler(item.friendly_URL);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                >
                  <AspectRatio
                    ratio="1"
                    sx={{
                      width: 150,
                      top: "-1rem",
                      marginRight: "-2rem",
                      zIndex: "1",
                      borderRadius: "100%",
                      overflow: "hidden",
                      "@media (max-width: 600px)": {
                        marginRight: "-0.1rem",
                        width: "50%",
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <img
                      src={
                        item.imageUrl ||
                        "/static/media/temp-horse.cc8728a6d7a678026833.png"
                      }
                      loading="lazy"
                      style={{
                        objectFit: "contain",
                        borderRadius: "100%",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </AspectRatio>
                  <Card
                    sx={{
                      width: "100%",
                      maxWidth: 370,
                      boxShadow: "lg",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "30px",
                      padding: "3px",
                      "@media (max-width: 600px)": {
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        padding: "1.2rem",
                        "@media (max-width: 600px)": {
                          flex: 2,
                          padding: "8px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Typography
                        level="title-lg"
                        id="card-description"
                        style={{
                          color: "#217a77",
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {item.horse_name} ||{" "}
                        {new Date(item?.birth_year).getFullYear()} ||{" "}
                        {item.gender == 'Male' ?
                          <MaleIcon
                            fontSize="inherit"
                            className={` ${classes.male}`}
                          />
                          :
                          <FemaleIcon
                            fontSize="inherit"
                            className={` ${classes.female}`}
                          />
                      }

                      </Typography>
                      <Typography
                        level="title-lg"
                        style={{
                          color: "#000000",
                          opacity: 0.9,
                          marginTop: "8px",
                        }}
                      >
                        <span className={classes.bold}>Owner:</span>{" "}
                        {item?.owner_name !== ""
                          ? item?.owner_name
                          : "Not Specified"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            )
            : colt === false && mare === false && stallion === false && filly === false ?
            (
              <Grid item xs={12} sm={12} md={4} key={item.id}>
                <div
                  onClick={() => {
                    breedDetailNavigateHandler(item.friendly_URL);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                >
                  <AspectRatio
                    ratio="1"
                    sx={{
                      width: 150,
                      top: "-1rem",
                      marginRight: "-2rem",
                      zIndex: "1",
                      borderRadius: "100%",
                      overflow: "hidden",
                      "@media (max-width: 600px)": {
                        marginRight: "-0.1rem",
                        width: "50%",
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <img
                      src={
                        item.imageUrl ||
                        "/static/media/temp-horse.cc8728a6d7a678026833.png"
                      }
                      loading="lazy"
                      style={{
                        objectFit: "contain",
                        borderRadius: "100%",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </AspectRatio>
                  <Card
                    sx={{
                      width: "100%",
                      maxWidth: 370,
                      boxShadow: "lg",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "30px",
                      padding: "3px",
                      "@media (max-width: 600px)": {
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        padding: "1.2rem",
                        "@media (max-width: 600px)": {
                          flex: 2,
                          padding: "8px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Typography
                        level="title-lg"
                        id="card-description"
                        style={{
                          color: "#217a77",
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {item.horse_name} ||{" "}
                        {new Date(item?.birth_year).getFullYear()} ||{" "}
                        {item.gender == 'Male' ?
                          <MaleIcon
                            fontSize="inherit"
                            className={` ${classes.male}`}
                          />
                          :
                          <FemaleIcon
                            fontSize="inherit"
                            className={` ${classes.female}`}
                          />
                      }

                      </Typography>
                      <Typography
                        level="title-lg"
                        style={{
                          color: "#000000",
                          opacity: 0.9,
                          marginTop: "8px",
                        }}
                      >
                        <span className={classes.bold}>Owner:</span>{" "}
                        {item?.owner_name !== ""
                          ? item?.owner_name
                          : "Not Specified"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            )
            :
            (<></>)
          ))}
      </Grid>
    </>
  );
}

function PaginatedItems({ itemsPerPage, query, gender, resetSearchQuery }) {
  const breedId = useParams().breedId;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  

  const fetchData = async () => {
    const response = await viewbreed(breedId);
    response
      .json()
      .then((horses) => ({
        horses: horses,
        status: response.status,
      }))
      .then((res) => {
        console.log(res.horses.horses);
        const allItems = res.horses.horses;

        console.log(allItems);

        const filteredItems = allItems.filter((item) =>
          item.horse_name.toLowerCase().includes(query.toLowerCase())
        );
        // console.log("filteredItems", filteredItems);
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(filteredItems.slice(itemOffset, endOffset));
        // console.log("current Items", currentItems);
        setPageCount(Math.ceil(filteredItems.length / itemsPerPage));
      })
      .catch((error) => {
        console.error("Error fetching breed data:", error);
      });
  };

  useEffect(() => {
    fetchData();
    // console.log("pageCount", pageCount);
  }, [itemOffset, itemsPerPage, query]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className={classes["breed-list"]}>
      <Items
        currentItems={currentItems}
        query={query}
        gender={null}
        resetSearchQuery={resetSearchQuery}
      />
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <KeyboardArrowRightIcon
            sx={{
              fontSize: "25px",
            }}
          />
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={
          <KeyboardArrowLeftIcon
            sx={{
              fontSize: "25px",
            }}
          />
        }
        renderOnZeroPageCount={null}
      />
    </div>
  );
}

// // Add a <div id="container"> to your HTML to see the componend rendered.
// ReactDOM.render(
//   <PaginatedItems itemsPerPage={4} />,
//   document.getElementById("container")
// );

export default PaginatedItems;
