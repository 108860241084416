import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import tempFarm from "../../../assets/images/temp-farm.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AspectRatio from "@mui/joy/AspectRatio";

// import ReactDOM from "react-dom";

import classes from "./FarmList.module.css";

import { getfarms } from "../../../apis";
import { Button } from "@mui/material";

function Items({ currentItems, query, resetSearchQuery, filteredItems }) {
  // console.log("CurrentItems: ", currentItems);
  const navigate = useNavigate();

  const farmDetailNavigateHandler = (friendly_URl) => {
    if (friendly_URl !== "null" || friendly_URl !== null) {
      navigate(`${friendly_URl}`);
    }
  };

  const itemsToDisplay = query !== "" ? filteredItems : currentItems;

  // console.log("here", query);
  return (
    <>
      {query !== "" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="primary"
            style={{
              color: "#0f2d25",
              margin: 0,
            }}
            onClick={resetSearchQuery}
          >
            View All Farms
          </Button>
        </div>
      ) : (
        <></>
      )}
      <Grid
        container
        spacing={8}
        // marginTop={"-50rem"}
        justifyContent="center"
        style={{ marginBottom: "3rem" }}
      >
        {itemsToDisplay.map((item, index) => (
          <Grid item xs={12} md={4} key={item.index}>
            <div
              onClick={() => {
                farmDetailNavigateHandler(item.friendly_URl);
              }}
              style={{
                cursor: "pointer",
                textAlign: "center",
                marginBottom: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                marginTop: "20px",
              }}
            >
              <AspectRatio
                ratio="1"
                sx={{
                  width: 160,
                  top: "-0.938rem",
                  marginRight: "-2.1rem",
                  zIndex: "1",
                  "@media (max-width: 600px)": {
                    marginRight: "0.1rem",
                    width: "70%",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                }}
                style={{ borderRadius: "50%" }}
              >
                <img
                  src={item.image != "" ? item.image : tempFarm}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </AspectRatio>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  boxShadow: "lg",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  position: "relative",
                  borderRadius: "30px",
                }}
              >
                <CardContent
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                    padding: "1.2rem",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: "#217a77",
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {item.stud_farm_name}
                  </Typography>
                </CardContent>
              </Card>
              {/* <img
                src={item.image != "" ? item.image : tempFarm}
                alt={item.stud_farm_name}
              />
              <h2 className={classes["farm-name"]}>{item.stud_farm_name}</h2> */}
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function PaginatedItems({ itemsPerPage, data, query, resetSearchQuery }) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const filteredItems = data.filter(
    (item) =>
      item.stud_farm_name &&
      item.stud_farm_name.toLowerCase().includes(query.toLowerCase())
  );

  // console.log("filtered items", filteredItems);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(
      Math.ceil(
        (query !== "" ? filteredItems.length : data.length) / itemsPerPage
      )
    );
  }, [itemOffset, itemsPerPage, data, filteredItems.length, query]);

  // useEffect(() => {
  //   const endOffset = itemOffset + itemsPerPage;
  //   // console.log(data);
  //   setCurrentItems(data.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(data.length / itemsPerPage));
  // }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <div className={classes["farm-list"]}>
      {/* <Items currentItems={currentItems} query={query} /> */}
      <Items
        currentItems={query !== "" ? filteredItems : currentItems}
        query={query}
        resetSearchQuery={resetSearchQuery}
        filteredItems={filteredItems}
      />
      {/* {filteredItems?.length > itemsPerPage && ( */}
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <KeyboardArrowRightIcon
            sx={{
              fontSize: "25px",
            }}
          />
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={
          <KeyboardArrowLeftIcon
            sx={{
              fontSize: "25px",
            }}
          />
        }
        renderOnZeroPageCount={null}
      />
      {/* )} */}
    </div>
  );
}

// // Add a <div id="container"> to your HTML to see the componend rendered.
// ReactDOM.render(
//   <PaginatedItems itemsPerPage={4} />,
//   document.getElementById("container")
// );

export default PaginatedItems;
