import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import tempMemberCard from "../../../assets/images/temp-member.png";
import tempFarm from "../../../assets/images/temp-farm.png";
import tempHorse from "../../../assets/images/horse.png";
import { InputBase, IconButton, Paper } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SearchIcon from "@mui/icons-material/Search";
import logoMask from "../../../assets/images/logo-mask.png";
import { Link } from "react-router-dom";
import classes from "./FarmDetails.module.css";
import { viewfarm } from "../../../apis";
import Carousel from "react-material-ui-carousel";
import Stack from "@mui/material/Stack";
import {
  useMediaQuery,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
const theme = createTheme();

const FarmDetails = () => {
  const farmId = useParams().farmId;
  // const [farmActive, setFarmActive] = useState(true);

  const [FARM_DATA, setFarmData] = useState([]);

  const [images, setFarmImages] = useState([]);

  const shortenUrl = (url) => {
    const maxLength = 25;
    if (url.length <= maxLength) {
      return url;
    }
    const truncatedUrl = url.substring(0, maxLength - 3) + "...";
    return truncatedUrl;
  };

  const fetchData = async () => {
    const response = await viewfarm(farmId);
    // console.log(response.json());
    response
      .json()
      .then((farm) => ({
        farm: farm,
        status: response.status,
      }))
      .then((res) => {
        setFarmData(res.farm.farm);
        setFarmImages(res.farm.farm.images);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.main}>
      <div className={classes["initial-display"]}>
        <div className={classes["initial-text"]}>
          <div className={classes["masked-logo"]}>
            <img src={logoMask} alt="Masked Logo" />
          </div>
          <div className={classes["breed-footer"]}>
            <span className={classes["social-handles"]}>
              <a
                href={"https://www.facebook.com/groups/570293584166368/"}
                style={{ textDecoration: "none", color: "white", zIndex: 1 }}
                target={"_blank"}
              >
                <FacebookIcon />
              </a>
              {/* <YouTubeIcon /> */}
            </span>
            <div>
              <h1>FARM PROFILE</h1>
              {/* <p>Breed Database / Farm Details</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className={classes["breed-details"]}>
        <div className={classes["breed-image"]}>
          <img
            src={FARM_DATA?.image || tempFarm}
            alt={FARM_DATA?.stud_farm_name}
          />
        </div>
        <div className={classes["breed-table"]}>
          <h1>{FARM_DATA?.stud_farm_name}</h1>
          <table style={{ borderSpacing: "0px auto", fontSize: "20px" }}>
            <tbody>
              <tr>
                <td className={classes.title}>Owner(s):</td>
                <td>
                  {FARM_DATA?.farmOwners?.map((owner, index) => (
                    <Link
                      to={`/members/${owner?.friendly_url}`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {index == FARM_DATA?.farmOwners?.length - 1 ? (
                        <span>
                          {owner?.first_name + " " + owner?.last_name}
                        </span>
                      ) : (
                        <span>
                          {owner?.first_name + " " + owner?.last_name + ", "}
                        </span>
                      )}
                    </Link>
                  ))}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>Website:</td>
                <td>
                  {FARM_DATA?.website ? (
                    <a
                      href={FARM_DATA?.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      {shortenUrl(FARM_DATA?.website)}
                    </a>
                  ) : (
                    <Typography variant={isSmallScreen ? "body2" : "body1"}>
                      No Website Available
                    </Typography>
                  )}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>Phone Number:</td>
                <td>
                  {FARM_DATA?.phone ? (
                    FARM_DATA?.phone
                  ) : (
                    <Typography variant={isSmallScreen ? "body2" : "body1"}>
                      No Phone Number Specified
                    </Typography>
                  )}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>Address:</td>
                <td>
                  {FARM_DATA?.address ? (
                    FARM_DATA?.address
                  ) : (
                    <Typography variant={isSmallScreen ? "body2" : "body1"}>
                      No Address Specified
                    </Typography>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Gallery */}
      <div className={classes["breed-details"]} style={{ float: "right" }}>
        <div className={classes["breed-image"]}>
          <Carousel>
            {images.map((image, i) => (
              <img key={i} src={image} alt={FARM_DATA?.stud_farm_name} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default FarmDetails;
