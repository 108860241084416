import React, { useState, useEffect } from "react";
import { InputBase, IconButton, Paper } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SearchIcon from "@mui/icons-material/Search";
import logoMask from "../../assets/images/logo-mask.png";

import classes from "./Members.module.css";
import MemberList from "./MemberElements/MemberList";

import { getmembers } from "../../apis";

const Members = () => {
  const [members, setMembers] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [queryToSend, setQueryToSend] = useState("");
  const fetchData = async () => {
    const response = await getmembers();
    response
      .json()
      .then((members) => ({
        members: members,
        status: response.status,
      }))
      .then((res) => {
        if (res.status == 200) {
          setMembers(res.members.members);
          console.log("Members: ", res.members.members);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchClick = () => {
    setQueryToSend(searchQuery);
  };

  const resetSearchQuery = () => {
    setSearchQuery("");
    setQueryToSend("");
  };

  return (
    <div>
      <div className={classes["initial-display"]}>
        <div className={classes["initial-text"]}>
          <div className={classes["masked-logo"]}>
            <img src={logoMask} alt="Masked Logo" />
          </div>
          <div className={classes["breed-footer"]}>
            <span className={classes["social-handles"]}>
              <a
                href={"https://www.facebook.com/groups/570293584166368/"}
                style={{ textDecoration: "none", color: "white", zIndex: 1 }}
                target={"_blank"}
              >
                <FacebookIcon />
              </a>
              {/* <YouTubeIcon /> */}
            </span>
            <div>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid white",
                  borderRadius: "100px",
                  width: 300,
                }}
              >
                <IconButton
                  sx={{ p: "10px", color: "white" }}
                  aria-label="menu"
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1, color: "white" }}
                  placeholder="Search Member Name"
                  inputProps={{ "aria-label": "search google maps" }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Paper>
              <button onClick={handleSearchClick}>SEARCH</button>
            </div>
          </div>
          <div className={classes.AboutDiv}>
            <div className={classes.AboutVectorImg}>
              <h1>MEMBERS</h1>
              <p
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "-1rem",
                }}
              >
                A directory of registered Members in the Arabian Equestrian Club
                of Pakistan (AECP)
              </p>
            </div>
          </div>
        </div>
      </div>

      {members?.length > 0 && (
        <MemberList
          itemsPerPage={12}
          data={members}
          query={queryToSend}
          resetSearchQuery={resetSearchQuery}
        />
      )}
    </div>
  );
};

export default Members;
