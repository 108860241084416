import React, { Fragment, useEffect, useState } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import ReactPaginate from "react-paginate";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AboutLineColorImg from "../../assets/images/heading-dec.png";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ImageCardFor from "../../assets/images/horse-carousel-1.jpg";
import ImageCardFor1 from "../../assets/images/horse-carousel-2.jpg";
import ImageCardFor2 from "../../assets/images/horse-carousel-3.jpg";
import logoMask from "../../assets/images/logo-mask.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import classes from "./AboutAECP.module.css";
import { NewsContent, getGallary } from "../../apis/aboutApis";
import Carousel from "react-material-ui-carousel";
import { CustomLeftArrow, CustomRightArrow } from "../Landing/Landing";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BreedModalStyle = {
  width: "90%",
  maxWidth: "1024px",
  height: "80%",
  top: "10%",
  left: "5%",
  right: "5%",
  overflow: "hidden",
  "@media (max-width: 768px)": {
    height: "400px",
    top: "5%",
    left: "5%",
    right: "5%",
  },
};

function Gallery({ itemsPerPage = 6, notShowHeader }) {
  const [gallery, setgallery] = useState();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [showCarousel, setShowCarousel] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getGallary();
      if (response.status === 200) {
        const galleryData = response.data.galery;
        setgallery(galleryData);
        console.log("gallary: ", galleryData);
      }
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (gallery) {
      const newEndOffset = Math.min(itemOffset + itemsPerPage, gallery.length);
      setCurrentItems(gallery.slice(itemOffset, newEndOffset));
      setPageCount(Math.ceil(gallery.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, gallery]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % gallery.length;
    setItemOffset(newOffset);
  };

  const openModal = (images) => {
    setSelectedImages(images);
    setShowCarousel(true);
  };

  const closeModal = () => {
    setShowCarousel(false);
    setSelectedImages([]);
  };

  return (
    <div>
      <div className={classes["initial-display"]}>
        <div className={classes["initial-text"]}>
          <div className={classes["masked-logo"]}>
            <img src={logoMask} alt="Masked Logo" />
          </div>
          <div className={classes["breed-footer"]}>
            <span className={classes["social-handles"]}>
              <a
                href={"https://www.facebook.com/groups/570293584166368/"}
                style={{
                  textDecoration: "none",
                  color: "white",
                  zIndex: 1,
                }}
                target={"_blank"}
              >
                <FacebookIcon />
              </a>
              {/* <YouTubeIcon /> */}
            </span>
          </div>
          <div className={classes.AboutDiv}>
            <div className={classes.AboutVectorImg}>
              <h1>GALLERY</h1>
            </div>
          </div>
        </div>
      </div>

      <Grid container spacing={4} textAlign={"-webkit-center"} mt={10}>
        {currentItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Fragment key={item.id}>
              <Card sx={{ maxWidth: 345 }}>
                <LazyLoadImage
                  src={item.preview_img}
                  alt={`Item ${item.id}`}
                  width="100%"
                  height="150px"
                  effect="blur"
                  style={{ objectFit: "cover" }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.title}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Button
                    size="medium"
                    onClick={() => openModal(item.imgs)}
                    className={classes["readMoreBtn"]}
                  >
                    View Album
                  </Button>
                </CardActions>
              </Card>
            </Fragment>
          </Grid>
        ))}
      </Grid>

      <Rodal
        customStyles={BreedModalStyle}
        enterAnimation="slideDown"
        leaveAnimation="zoom"
        closeOnEsc
        visible={showCarousel}
        onClose={closeModal}
        showCloseButton={false}
      >
        <Carousel
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          swipeable={false}
          draggable={false}
          infinite={true}
          keyBoardControl={true}
          transitionDuration={700}
          containerClass="carousel-container"
          itemClass="carousel-item-padding-40-px"
        >
          {selectedImages.map((image, index) => (
            <div key={index} className={classes["BreedModalDiv1"]}>
              <h2 className={classes["ModalMemberName"]}>
                {image.title}
                <span className={classes["ModalMemberPosition"]}>
                  Published: {new Date(image.publish_date).toDateString()}
                </span>
              </h2>
              <div className={classes["CarouselImageContainer"]}>
                <img src={image.img_name} alt={`Image ${index}`} />
              </div>
            </div>
          ))}
        </Carousel>
      </Rodal>

      <div className={classes["breed-list1"]}>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <KeyboardArrowRightIcon
              sx={{
                fontSize: "25px",
              }}
            />
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <KeyboardArrowLeftIcon
              sx={{
                fontSize: "25px",
              }}
            />
          }
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}

export default Gallery;
