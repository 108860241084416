import React from "react";
import classes from "./AboutAECP.module.css";
import AboutLineColorImg from "../../assets/images/heading-dec.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import logoMask from "../../assets/images/image.png";
// import logoMask from "../../../assets/images/image.png";

const AECPConstitution = ({content}) => {
  const rulesHeading = [
    {
      rule_name: "PART 1: MEMORANDUM",
      content: `<ol><li><strong>Name and Objectives of the Club.</strong> The club shall be called the <strong>ARABIAN EQUESTRIAN CLUB OF PAKISTAN (AECP).</strong> It shall endeavor in every way to promote the general welfare and improvement of Arabian Horses by the following means inter alia: -
  <ol style='list-style-type: lower-alpha; margin: 10px;'><li>Identification of Arabian Horses.</li>
  <li style="margin: 10px;">Registration of pure-bred Arabian Horses Anglo and Half Arabians</li>
  <li style="margin: 10px;">Framing and enforcing the club constitution/ rules governing conduct of members, Exhibition of Horses and conduction of shows / trials / tests. Conduction and participation in sports events like jumping, endurance and tent pegging.</li>
  <li style="margin: 10px;">Licensing of shows etc.</li>
  <li style="margin: 10px;">Adjudication on charges of infringement of the club constitution, rules and punishment by suspension or disqualification of persons found guilty of infringements.</li>
  <li style="margin: 10px;">Preservation and promotion of the breed by arranging other social events.</li>
  <li style="margin: 10px;">Arbitration in cases of dispute.</li>
  <li style="margin: 10px;">Awarding, Champion, Championship Points and other certificates.</li>
  <li style="margin: 10px;">Awarding Challenge Trophies.</li>
  <li style="margin: 10px;">Registration of Associations, Clubs and Societies connected with horses.</li>
  <li style="margin: 10px;">Publication of a Stud Book.</li>
  <li style="margin: 10px;">Publication of Club Gazette / News Letter.</li>
  <li style="margin: 10px;">Provision of officers for the transaction of business.</li></ol>
  </li>
  <li>The Arabian Equestrian Club of Pakistan shall be a non-profit making club and its surpluses shall not be used for any purpose other than the objectives of the club and welfare of horses. The club and its members will work without any religious, political or gender discrimination.</li></ol>
  `,
    },
    {
      rule_name: "PART 2: OFFICE BEARERS AND MANAGING COMMITTEE",
      content: `<ol><li style="margin-bottom: 10px;"><strong>Patron in Chief and Patrons.</strong> The club may have a Patron-in-Chief and Patrons who shall be appointed by Managing Committee.</li>
  <li style="margin-bottom: 10px;"><strong>Office Bearers of the Club.</strong> The club shall have the following office Bearers: -
  <ol style="margin: 10px; list-style-type: lower-alpha;"><li style="margin: 10px;">President</li>
  <li style="margin: 10px;">Vice President</li>
  <li style="margin: 10px;">Secretary</li>
  <li style="margin: 10px;">Treasurer</li>
  <li style="margin: 10px;">National Breed Warden</li>
  <li style="margin: 10px;">The Managing Committees shall elect the above-mentioned office-bearers for a term of three years. They shall be ex-officio members of the Managing Committee.</li></ol></li>
  
  <li style="margin-bottom: 10px;"><strong>Managing Committee.</strong> The club shall have a Managing Committee. All the affairs of the club shall be managed by and its power delegated to the managing committee which shall consist of fifteen members.</li>
  <li style="margin-bottom: 10px;"><strong>Election of Managing Committee.</strong> The managing committee shall be elected by the permanent members of the club at a special general meeting especially called for the purpose. Nominations for membership of the Managing Committee shall be filed with the Honorary Secretary of the Club thirty days before the date of the election. Withdrawals
  shall be permitted seven days before the date of election. These permanent members who cannot attend the general meeting, can send their ballot by post.</li>
  <li style="margin-bottom: 10px;"><strong>Only Permanent Members Eligible to Hold.</strong> Only the permanent members of the club shall be eligible to hold office mentioned in clauses 2 & 3 above.</li>
  <li style="margin-bottom: 10px;"><strong>Vacancy.</strong> Any vacancy in the offices mentioned in clauses 1-3 above shall be filled up by the Managing Committee at a meeting of which notice to fill such vacancy shall be given.</li>
  <li style="margin-bottom: 10px;"><strong>Managing Committee - Quorum.</strong> The managing committee shall hold its meeting at such time as may from time to time be decided. Six shall form a quorum.</li>
  <li style="margin-bottom: 10px;"><strong>Notice of Meeting and Agenda.</strong> Seven days’ notice of the day and hour when the managing committee is to be held shall be sent to every member of the Managing Committee, together with a copy of the agenda and business to be transacted.</li>
  <li style="margin-bottom: 10px;"><strong>Business which may be Transacted at a Meeting.</strong> No business shall be transacted by the managing committee unless notice thereof appears on the agenda, except in respect of ordinary office routine or matters necessary to be dealt with arising out of the Minutes of the previous Meeting, or which in the opinion of the chairman of the Meeting is urgent.</li>
  <li style="margin-bottom: 10px;"><strong>Rescinding a Resolution.</strong> No resolution passed by majority of the managing committee voting at a meeting shall be varied or rescinded within three months of such resolution being passed.</li>
  <li style="margin-bottom: 10px;"><strong>Sub Committees.</strong> The managing committee may constitute any of following sub committees to facilitate disposal of the Club’s business: -
  
  <ol style="margin: 10px; list-style-type: lower-alpha;"><li style="margin: 10px;">Show Regulations</li>
  <li style="margin: 10px;">Judging</li>
  <li style="margin: 10px;">Breed Standards</li>
  <li style="margin: 10px;">Stud book and registrations</li>
  <li style="margin: 10px;">Finance and general purposes</li>
  <li style="margin: 10px;">Disciplinary</li>
  <li style="margin: 10px;">Adhoc sub-committee to advise on any specific matter.</li>
  <li style="margin: 10px;">Sports.</li></ol></li>
  
  <li style="margin-bottom: 10px;"><strong>Delegation of Power to Sub-Committees.</strong> The Managing Committee may delegate any of its powers and functions to any sub-committee and may likewise at any time revoke or amend such delegation save that the Managing Committee shall not delegate to any sub-committee powers to suspend or disqualify any person.</li>
  <li style="margin-bottom: 10px;"><strong>Meeting of Sub Committees - Quorum.</strong> The quorum of any meeting of any sub- committee shall be decided by the sub-committee.</li>
  <li style="margin-bottom: 10px;"><strong>Annual General Meeting.</strong> There must be an Annual General Meeting of the Permanent Members of the Club held in the first six months of each financial year to receive a report on the operations and general concerns of the Club up to the date of the meeting, to receive from the auditors and if thought fit to approve a report on the financial position of the Club at the end of the preceding financial year and a summary of all the audited accounts and the balance sheet as at the same date and to discuss any proposals which may be submitted for decision or discussion.</li>
  <li style="margin-bottom: 10px;"><strong>Special General Meeting.</strong> The Managing Committee may at their discretion, by circular, specifying the objects of the Meeting, call a special general meeting of the club at any time, and shall call such a meeting upon the requisition of twenty five permanent members of the Club made in writing specifying the object for which it is required signed by the members making the same and deposited with Honorary Secretary. No Business shall be discussed at a Special General Meeting other than that for which it is called. In the event of Managing Committee failing to convene a Special General Meeting within thirty days of the receipt by the Honorary Secretary of the requisition above provided, the requisitions may call such Special General Meeting to be held after completion of 60 days since the deposit of the requisition with the Honorary Secretary.</li>
  <li style="margin-bottom: 10px;"><strong>Notice of and Quorum or General Meeting.</strong> Thirty days’ notice, in writing, of any General Meeting (whether ordinary or special) of the Club shall be given. The quorum of an Annual or a Special General Meeting shall be 20.</li>
  <li style="margin-bottom: 10px;"><strong>Quorum not Present.</strong> If within thirty minutes of the time appointed for holding a general, special general or managing committee meeting a quorum shall not be present, the same shall stand dissolved.</li>
  <li style="margin-bottom: 10px;"><strong>Executives and Staff.</strong> Executives and Staff may be appointed by the Managing Committee and be responsible to them for the management of the Club in accordance with their instructions.</li>
  <li style="margin-bottom: 10px;"><strong>Financial Year.</strong> The financial year of the Club shall commence from the 1st July.</li>
  <li style="margin-bottom: 10px;"><strong>Accounts.</strong> Once every year the accounts of the Club shall be audited and the correctness of the balance sheet and of the income and expenditure accounts shall be ascertained and certified.</li>
  <li style="margin-bottom: 10px;"><strong>Banking Arrangements.</strong> All subscriptions, fees, club bills, donations or otherwise received on account of revenue shall be paid to the Bankers of the Club, and cheques upon the bank account shall be signed as the Managing Committee shall from time to time direct.</li>
  <li style="margin-bottom: 10px;"><strong>Constitution to be Printed.</strong> The constitution of the club shall be printed and no Member shall be absolved from observance thereof on the excuse of not having received a copy or of ignorance of its contents.</li>
  <li style="margin-bottom: 10px;"><strong>Alteration of Constitution or Bylaws</strong>
  <ol style="margin: 10px; list-style-type: lower-alpha;"><li style="margin: 10px;">The constitution of the Arabian Equestrian Club of Pakistan may only be amended by a resolution passed by a majority consisted of two-third of the members present and voting at a General Meeting. Thirty days clear notice setting out the terms of any motion to amend the Constitution must be sent by post to all members but this shall not debar any amendment to the motion verbally at the General Meeting at which the motion is considered without prior notice thereof being given. Any amendment moved at the general meeting shall be deemed carried and incorporated in the substantive motion to amend the constitution if passed by simple majority of those present and voting.
  <li style="margin: 10px;">Oral amendments may not introduce matters beyond the scope of the motion to be amended especially if these matters might have themselves formed the subject of a separate proposal. Any amendment to a motion for the alteration of the Constitution/ Rules must be restricted to such matters as the improvement of the language used, or, at the most, the removal of an ambiguity.</li></ol></li>
  <li style="margin-bottom: 10px;"><strong>Legal Proceedings.</strong> The club shall bear all liability incurred in consequence of any statement made or published whether verbally or in writing by or with the authority of the club who shall indemnify any Member of the Managing Committee or of the Sub- Committee thereof or any official of the Club against all personal liability or loss incurred thereby.</li>
 `,
    },
    {
      rule_name: "PART 3: MEMBERSHIP",
      content: `<ol><li style="margin-bottom: 10px;"><strong>Number of Permanent Members.</strong> Membership of the club shall consist of not more than fifty (50) Permanent Members, to be increased from time to time as two-thirds majority of Members attending a General Meeting may decide. Not more than thirty new Permanent Members shall be elected in any one financial year. Only permanent members shall be entitled to participate and vote in a General Meeting and be Members of the Managing Committee.</li>
 <li style="margin-bottom: 10px;"><strong>Managing Committee to Elect Members.</strong> The election of members of the Club is vested solely in the Managing Committee and shall be by ballot. Candidates are required to receive two-third majority of members present at the meeting to which their names are submitted.</li>
 <li style="margin-bottom: 10px;"><strong>Proposers and Seconders or Candidates.</strong> Candidates must be proposed and seconded by Permanent Members of the Club.</li>
 <li style="margin-bottom: 10px;"><strong>Election of Temporary Members.</strong> Candidates shall, in the first instance, be enrolled as Temporary Members by the Honorary Secretary of the Club.</li>
 4a. Election of Life Members. The Managing Committee may elect Life Members on payment of Rs. 100,000/-. They shall not be required to pay the fees annually. They shall be classified as Temporary Members and shall be eligible as Permanent Members as stipulated in para 7 below.</li>
 <li style="margin-bottom: 10px;"><strong>Election of Permanent Members.</strong> A Temporary member shall be eligible for election as a permanent member, one year after his election as a temporary member. The managing committee shall invite such temporary members to become permanent members who have taken an active interest to promote the affairs of the Arabian Equestrian Club of Pakistan. The decision of the managing committee shall be final in this respect.</li>
 <li style="margin-bottom: 10px;"><strong>Publication of Names of Permanent and Temporary Members.</strong> Names and addresses of the newly elected permanent and temporary members shall be published in the Arabian Equestrian Gazette at the first opportunity after the election of the candidates.</li>
 <li style="margin-bottom: 10px;"><strong>Subscription.</strong> The following subscription shall be payable: -
 <ol style="margin: 10px; list-style-type: lower-alpha;"><li style="margin: 10px;">Entrance fee on election as temporary member	Rs. 5000.00</li>
 <li style="margin: 10px;">On Election as permanent member	Rs. 10000.00</li>
 <li style="margin: 10px;">Annual membership fee payable on 1st July	Rs. 2000.00</li>
 <li style="margin: 10px;">Subscription to be paid by members of the club shall be fixed, from time to time, at a General Meeting.</li></ol></li>
 <li style="margin-bottom: 10px;"><strong>Entrance Fee and Subscription Payable on Notification.</strong> Every new member shall pay his entrance fee and annual subscription on receiving the official notification of his election and if these are not paid within 30 days his name shall not be entered on the list of members of the club until he has accounted for the delay to the satisfaction of the managing committee.</li>
 <li style="margin-bottom: 10px;"><strong>Acknowledgment of Club Rules.</strong> As payment of the entrance fee and subscription according to the rules will entitle the member to every benefit and privilege of the club. Such payment shall be his distinct acknowledgement of, and acquiescence in, the constitution and rules of the Arabian Equestrian Club of Pakistan.</li>
 <li style="margin-bottom: 10px;"><strong>Overdue Subscription.</strong> The honorary secretary shall, on 1st August in each year, give written notice to any member who has not paid his subscription due on 1st July. If the subscription remains unpaid, a second notice shall be issued on 1st October and if the subscription is still not paid, the member’s name shall be removed from the list of members on the 31st October.</li>
 <li style="margin-bottom: 10px;"><strong>Honorary Life Members.</strong> The Managing Committee shall have power to recommend to a General meeting the election of Honorary Life Members for outstanding and distinguished services to the Arabian Equestrian Club of Pakistan. Notice of a proposal under this section must appear on the agenda of general meeting. Honorary life member shall have all rights and privileges of membership but shall not be liable for paying further membership subscription.</li>
 <li style="margin-bottom: 10px;"><strong>Notice to Member.</strong> Each member shall communicate his address or that of his banker, from time to time to the honorary secretary and all notices sent to that address shall be deemed to be good service upon him at the time of posting. No member, who fails to comply with this rule, shall be entitled to receive any notice.</li>
 <li style="margin-bottom: 10px;"><strong>Resignation of Membership.</strong> Any member who wishes to resign must give notice of his intention to the Honorary Secretary before 1st June otherwise he shall be liable for his subscription for the following year.</li>
 <li style="margin-bottom: 10px;"><strong>Expulsion of Members.</strong> It shall be the duty of the managing committee if at any time they shall be of the opinion that the interests of the club so require, by letter to invite any member to withdraw from the club within a time specified in such letter, and in default of withdrawal to submit the question of his expulsion to a special meeting of the managing committee to be held within thirty days after the date of such letter. At such meeting the member whose expulsion is under consideration shall be allowed to offer an explanation of his conduct verbally, or in writing, and if thereupon two-third of the members present shall vote for his expulsion he shall thereupon cease to be a member of the club. Provided that voting at such special meeting shall be by ballot.</li>
 <li style="margin-bottom: 10px;"><strong>Representation of Members on Committee.</strong> No member of the club shall directly or indirectly appear to act before the managing committee or before any special or general meeting of the club in any professional capacity for any client other than managing committee or enter into any business contract or relationship with the club without making disclosure of his interest to the managing committee.</li>
 <li style="margin-bottom: 10px;"><strong>Members may not Give Publicity in the Press to Members Affecting the Managing of the Arabian Equestrian Club of Pakistan.</strong> No individual member shall give publicity to matters affecting the management of the club or to the proceedings at club meetings.</li></ol>
 `,
    },
  ];

  return (
    <div>
      <div className={classes["HistoryAECP"]}>
        <div>
          <div style={{ float: "left" }}>
            <h2 className={classes["AboutHeading"]}>AECP Constitution</h2>
          </div>
          <div style={{ float: "left" }}>
            <img
              src={AboutLineColorImg}
              alt="about img"
              className={classes.clublineImg}
            />
          </div>
          <div className={classes["clear"]}></div>
          <hr />
        </div>
        <div className={classes["newsMainDiv"]} style={{ paddingTop: 20 }}>
          <div className={classes["masked-logo1"]} style={{ paddingTop: 500 }}>
            <img src={logoMask} alt="" />
          </div>
            <a
              href={'https://admin.arabians.pk/public/AECP-Constitution.pdf'}
              target="_blank"
              type="button"
              className={''}
              style={{textDecoration: 'none', padding: 10, fontWeight: 900, backgroundColor: '#1b5f5a', color: 'white', borderRadius: 10}}
            >
              Download The AECP Constitution
            </a>
            <div style={{marginTop: 50}} key={0} className={classes["ConstitutionPart"]}>
              <p
                dangerouslySetInnerHTML={{
                  __html: content
                }}
              ></p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default AECPConstitution;
