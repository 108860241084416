import React, { Fragment, useEffect, useState } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import ReactPaginate from "react-paginate";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AboutLineColorImg from "../../assets/images/heading-dec.png";
import classes from "./Blogs.module.css";
import { NewsContent, getBlogs } from "../../apis/aboutApis";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ImageCardFor from "../../assets/images/AECP-tempimg.png";
import logoMask from "../../assets/images/logo-mask.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import { styled } from "@mui/system";
const BreedModalStyle = {
  width: "80%",
  height: "auto",
  top: "90px",
};

if (window.innerWidth <= 768) {
  BreedModalStyle.width = "100%";
  BreedModalStyle.padding = "1rem";
  BreedModalStyle.overflowX = "scroll";
}

const StyledCardMedia = styled(CardMedia)({
  objectFit: "contain",
  height: 140,
});

function Items({ currentItems, notShowHeader }) {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
    setShow(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setShow(false);
  };

  return (
    <div>
      <div className={classes["initial-display"]}>
        <div className={classes["initial-text"]}>
          <div className={classes["masked-logo"]}>
            <img src={logoMask} alt="Masked Logo" />
          </div>
          <div className={classes["breed-footer"]}>
            <span className={classes["social-handles"]}>
              <a
                href={"https://www.facebook.com/groups/570293584166368/"}
                style={{
                  textDecoration: "none",
                  color: "white",
                  zIndex: 1,
                }}
                target={"_blank"}
              >
                <FacebookIcon />
              </a>
              {/* <YouTubeIcon /> */}
            </span>
          </div>
          <div className={classes.AboutDiv}>
            <div className={classes.AboutVectorImg}>
              <h1>BLOGS</h1>
            </div>
          </div>
        </div>
      </div>

      <Grid container spacing={4} textAlign={"-webkit-center"} mt={10}>
        {currentItems?.map((fullNews) => (
          <Grid item xs={12} sm={6} md={4} key={fullNews.id}>
            <Fragment key={fullNews.id}>
              <Card sx={{ maxWidth: 345 }}>
                <StyledCardMedia
                  component="img"
                  alt=""
                  src={fullNews.hero_image}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {fullNews.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {fullNews.preview_text}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Button
                    size="medium"
                    onClick={() => openModal(fullNews)}
                    className={classes["readMoreBtn"]}
                  >
                    Read More
                  </Button>
                </CardActions>
              </Card>
            </Fragment>
          </Grid>
        ))}
      </Grid>

      <Rodal
        customStyles={BreedModalStyle}
        enterAnimation="slideDown"
        leaveAnimation="zoom"
        closeOnEsc
        visible={show}
        onClose={closeModal}
      >
        {selectedItem && (
          <div className={classes["BreedModalDiv"]}>
            <h2 className={classes["ModalMemberName"]}>
              {selectedItem.title}
              <span className={classes["ModalMemberPosition"]}>
                Published:{" "}
                {new Date(selectedItem.publishing_date).toDateString()}
              </span>
            </h2>
            <hr></hr>
            <div className={classes["modalImg"]}>
              <div>
                <p
                  className={classes["standardModalText"]}
                  dangerouslySetInnerHTML={{
                    __html: selectedItem.content
                      .replace(/&lt;/g, "<")
                      .replace(/&gt;/g, ">")
                      .replace(/&quot;/g, '"')
                      .replace(/&#39;/g, "'")
                      .replace(/&amp;/g, "&"),
                  }}
                ></p>
              </div>
            </div>
          </div>
        )}
      </Rodal>
    </div>
  );
}

function Blogs({ itemsPerPage = 6, notShowHeader }) {
  const [blogs, setBlogs] = useState();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const fetchData = async () => {
    try {
      const response = await getBlogs();
      if (response.status === 200) {
        const blogsData = response.data.blogs;
        setBlogs(blogsData);
        console.log("blogs: ", blogsData);
      }
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (blogs) {
      const newEndOffset = Math.min(itemOffset + itemsPerPage, blogs.length);
      setCurrentItems(blogs.slice(itemOffset, newEndOffset));
      setPageCount(Math.ceil(blogs.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, blogs]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % blogs.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className={classes["breed-list"]}>
        <Items notShowHeader={notShowHeader} currentItems={currentItems} />
      </div>
      <div className={classes["breed-list1"]}>
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <KeyboardArrowRightIcon
              sx={{
                fontSize: "25px",
              }}
            />
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={
            <KeyboardArrowLeftIcon
              sx={{
                fontSize: "25px",
              }}
            />
          }
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default Blogs;
