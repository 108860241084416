import React, { useState, useEffect } from "react";
import FarmList from "./FarmElements/FarmList";
import { InputBase, IconButton, Paper } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SearchIcon from "@mui/icons-material/Search";
import logoMask from "../../assets/images/logo-mask.png";
import tempFarm from "../../assets/images/temp-farm.png";

import classes from "./Farms.module.css";
import { getfarms } from "../../apis";

const Farms = () => {
  const [farms, setFarms] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [queryToSend, setQueryToSend] = useState("");

  const fetchData = async () => {
    const response = await getfarms();
    response
      .json()
      .then((farms) => ({
        farms: farms,
        status: response.status,
      }))
      .then((res) => {
        if (res.status == 200) {
          setFarms(res.farms.farms);
          console.log("Farms: ", res.farms.farms);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  // console.log("farms", farms);
  const handleSearchClick = () => {
    setQueryToSend(searchQuery);
  };

  const resetSearchQuery = () => {
    setSearchQuery("");
    setQueryToSend("");
  };

  return (
    <div className={classes["breed-database"]}>
      <div className={classes["initial-display"]}>
        <div className={classes["initial-text"]}>
          <div className={classes["masked-logo"]}>
            <img src={logoMask} alt="Masked Logo" />
          </div>
          <h1>FARMS</h1>
          <p
            style={{
              fontSize: "1.25rem",
              textAlign: "center",
              marginTop: "-1rem",
            }}
          >
            A directory of Farms registered with the Arabian Equestrian Club of
            Pakistan (AECP)
          </p>
          <div className={classes["breed-footer"]}>
            <span className={classes["social-handles"]}>
              <a
                href={"https://www.facebook.com/groups/570293584166368/"}
                style={{ textDecoration: "none", color: "white", zIndex: 1 }}
                target={"_blank"}
              >
                <FacebookIcon />
              </a>
              {/* <YouTubeIcon /> */}
            </span>
            <div>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid white",
                  borderRadius: "100px",
                  width: 300,
                }}
              >
                <IconButton
                  sx={{ p: "10px", color: "white" }}
                  aria-label="menu"
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1, color: "white" }}
                  placeholder="Search Farms"
                  inputProps={{ "aria-label": "search google maps" }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Paper>
              <button onClick={handleSearchClick}>SEARCH</button>
            </div>
          </div>
        </div>
      </div>
      {farms?.length > 0 && (
        <FarmList
          itemsPerPage={12}
          data={farms}
          query={queryToSend}
          resetSearchQuery={resetSearchQuery} // Pass the reset function
        />
      )}
    </div>
  );
};

export default Farms;
