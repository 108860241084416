import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getstuds } from "../../../apis";
import ReactPaginate from "react-paginate";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AspectRatio from "@mui/joy/AspectRatio";
import classes from "./StudList.module.css";
import { Button } from "@mui/material";

function Items({ currentItems, query, resetSearchQuery, filteredItems }) {
  // console.log("CurrentItems: ", currentItems);
  const navigate = useNavigate();

  const studDetailNavigateHandler = (friendly_URL) => {
    navigate(`/breed-database/${friendly_URL}`);
  };

  const itemsToDisplay = query !== "" ? filteredItems : currentItems;

  return (
    <>
      {query !== "" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="primary"
            style={{
              color: "#0f2d25",
              margin: 0,
            }}
            onClick={resetSearchQuery}
          >
            View All Studs
          </Button>
        </div>
      ) : (
        <></>
      )}
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="center"
      >
        {itemsToDisplay.map((item) => (
          <Grid item xs={12} sm={12} md={4} key={item.id}>
            <div
              onClick={() => {
                studDetailNavigateHandler(
                  item.var_name + "/" + item.friendly_URL
                );
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                marginTop: "20px",
              }}
            >
              <AspectRatio
                ratio="1"
                sx={{
                  width: 130,
                  top: "-1rem",
                  marginRight: "-2rem",
                  zIndex: "1",
                  "@media (max-width: 600px)": {
                    marginRight: "-0.1rem",
                    width: "50%",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                }}
                style={{ borderRadius: "50%" }}
              >
                <img
                  src={
                    item.imageUrl ||
                    "/static/media/temp-horse.cc8728a6d7a678026833.png"
                  }
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </AspectRatio>
              <Card
                sx={{
                  width: "100%",
                  maxWidth: 370,
                  boxShadow: "lg",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  position: "relative",
                  borderRadius: "30px",
                  padding: "3px",
                  "@media (max-width: 600px)": {
                    flexDirection: "column",
                    alignItems: "center",
                  },
                }}
              >
                <CardContent
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                    padding: "1.2rem",
                    "@media (max-width: 600px)": {
                      flex: 2,
                      padding: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  }}
                >
                  <Typography
                    level="title-lg"
                    id="card-description"
                    style={{
                      color: "#217a77",
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {item.horse_name} ||{" "}
                    {new Date(item?.birth_year).getFullYear()} ||{" "}
                    <MaleIcon
                      fontSize="inherit"
                      className={` ${classes.male}`}
                    />
                  </Typography>
                  <Typography
                    level="title-lg"
                    style={{
                      color: "#000000",
                      opacity: 0.9,
                      marginTop: "8px",
                    }}
                  >
                    <span className={classes.bold}>Owner:</span>{" "}
                    {item?.owner_name !== ""
                      ? item?.owner_name
                      : "Not Specified"}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function PaginatedItems({ itemsPerPage, data, query, resetSearchQuery }) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const filteredItems = data.filter(
    (item) =>
      item.horse_name &&
      item.horse_name.toLowerCase().includes(query.toLowerCase())
  );

  // console.log("filtered Items", filteredItems);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(
      Math.ceil(
        (query !== "" ? filteredItems.length : data.length) / itemsPerPage
      )
    );
  }, [itemOffset, itemsPerPage, data, filteredItems.length, query]);

  // useEffect(() => {
  //   const endOffset = itemOffset + itemsPerPage;
  //   console.log(data);
  //   setCurrentItems(data.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(data.length / itemsPerPage));
  // }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };

  return (
    <div className={classes["stud-list"]}>
      <Items
        currentItems={query !== "" ? filteredItems : currentItems}
        query={query}
        resetSearchQuery={resetSearchQuery}
        filteredItems={filteredItems}
      />

      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <KeyboardArrowRightIcon
            sx={{
              fontSize: "25px",
            }}
          />
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={
          <KeyboardArrowLeftIcon
            sx={{
              fontSize: "25px",
            }}
          />
        }
        renderOnZeroPageCount={null}
      />
    </div>
  );
}

// // Add a <div id="container"> to your HTML to see the componend rendered.
// ReactDOM.render(
//   <PaginatedItems itemsPerPage={4} />,
//   document.getElementById("container")
// );

export default PaginatedItems;
