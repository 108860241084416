import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import tempHorse from "../../../assets/images/temp-horse.png";
import Purebred from "../../../assets/images/breed-images/Purebred.jpg";
import StraightEgyptian from "../../../assets/images/breed-images/Straight Egyptian.jpg";
import HalfArabs from "../../../assets/images/breed-images/Half Arabs.jpg";
import AngloArabs from "../../../assets/images/breed-images/Anglo Arabs.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AspectRatio from "@mui/joy/AspectRatio";

import { getbreeds } from "../../../apis";

const BreedList = () => {
  const navigate = useNavigate();
  const [breedList, setBreedList] = useState([]);

  const fetchData = async () => {
    const response = await getbreeds();
    response
      .json()
      .then((variations) => ({
        variations: variations,
        status: response.status,
      }))
      .then((res) => {
        let temp = Object.entries(res.variations);
        setBreedList(temp[0][1]);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const breedListDetailNavigateHandler = (name) => {
    if (name !== "null" || name !== null) {
      navigate(name);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={8}
        justifyContent="center"
        style={{ marginBottom: "3rem" }}
      >
        {breedList &&
          breedList.map((list) => (
            <Grid item xs={12} md={4} key={list.id}>
              <div
                onClick={() => {
                  breedListDetailNavigateHandler(list.name);
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  marginBottom: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  marginTop: "20px",
                }}
              >
                <AspectRatio
                  ratio="1"
                  sx={{
                    width: 120,
                    top: "-0.938rem",
                    marginRight: "-2.1rem",
                    zIndex: "1",
                    "@media (max-width: 600px)": {
                      marginRight: "0.1rem",
                      width: "70%",
                      flexDirection: "column",
                      alignItems: "center",
                    },
                  }}
                  style={{ borderRadius: "50%" }}
                >
                  <img
                    src={
                      list.name == "Purebred"
                        ? Purebred
                        : list.name == "Straight Egyptian"
                        ? StraightEgyptian
                        : list.name == "Anglo Arabs"
                        ? AngloArabs
                        : list.name == "Half Arabs"
                        ? HalfArabs
                        : tempHorse
                    }
                    alt={list.name}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </AspectRatio>
                <Card
                  sx={{
                    width: "100%",
                    maxWidth: 400,
                    boxShadow: "lg",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    position: "relative",
                    borderRadius: "30px",
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      textAlign: "center",
                      padding: "1.2rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        color: "#217a77",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      {list.name}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default BreedList;
