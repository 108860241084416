import React, { Fragment } from "react";
import logo from "../../assets/images/logo.png";
import logoMask from "../../assets/images/logo-mask.png";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link, useNavigate } from "react-router-dom";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <Fragment>
      <div className={classes.footer}>
        <div className={classes["masked-logo"]}>
          <img src={logoMask} alt="" />
        </div>
        <img src={logo} alt="" />
        <p>
        Discover the Legacy of the Arabian Horse with AECP: Where Breeding, Competition, and Recreation Converge.
        </p>
        <div className={classes["footer-links"]}>
          <div>
            <span>
            <a href="/" style={{color: "white", textDecoration: "none"}}>Home</a></span>
            <span>
            <a href="/breed-database" style={{color: "white", textDecoration: "none"}}>AECP Database</a></span>
            <span>
            <a href="/members" style={{color: "white", textDecoration: "none"}}>Members</a></span>
          </div>
          <div>
            <span className={classes["stick"]}>
              <a href="/farms" style={{color: "white", textDecoration: "none"}}>Farms</a></span>
            <span>
              <a href="/events" style={{color: "white", textDecoration: "none"}}>Events</a></span>
            <span>
              <a href="/about-aecp" style={{color: "white", textDecoration: "none"}}>About the Club</a></span>
          </div>
        </div>
        <div className={classes.contact}>
          <span>
            <PhoneIcon sx={{ margin: "0 15px" }} />
            +92 42-37290084
          </span>
          <span>
            <LocationOnIcon sx={{ margin: "0 15px" }} />
            55-A, commercial broadway, Phase 8, DHA, Lahore
          </span>
          <span>
            <MailIcon sx={{ margin: "0 15px" }} />
            arabian.ecp@gmail.com
          </span>
        </div>
        <div className={classes["social-handles"]}>
        <a href={"https://www.facebook.com/groups/570293584166368/"} style={{textDecoration: 'none', color: "white"}} target={"_blank"}>
                    <FacebookIcon/>
                  </a>
          {/* <YouTubeIcon /> */}
        </div>
      </div>
      <div className={classes.copyright}>
        <p>AECP © 2022, All rights reserved - Powered by <a href="https://inspedium.com" style={{textDecoration: 'none', color: 'white', fontWeight: '900'}}>Inspedium Corp.</a></p>
      </div>
    </Fragment>
  );
};

export default Footer;
