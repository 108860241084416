import React, { useEffect, useRef } from "react";
import classes from "./Landing.module.css";
import EastIcon from "@mui/icons-material/East";
import FacebookIcon from "@mui/icons-material/Facebook";
import horseVideo from "../../assets/videos/Video.mp4";
// import ReactPlayer from "react-player";

const CarouselSlide = ({ imageSrc, altText }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      if (video) {
        video.play().catch((error) => console.error("Autoplay failed:", error));
      }
    };

    playVideo();
  }, []);

  return (
    <div className={classes["carousel-slide"]}>
      <div className={classes["video-container"]}>
        {/* <ReactPlayer
        url={horseVideo}
        playing={true}
        loop={true}
        muted
        controls={false}
      /> */}

        <video
          src={horseVideo}
          ref={videoRef}
          autoPlay
          loop
          playsInline
          controls={false}
          muted
          height={"100%"}
          width={"100%"}
        />
      </div>
      <div className={classes["initial-text"]}>
        <h1 className={classes["myHeading"]}>
          Arabian Equestrian Club of Pakistan
        </h1>
        <p className={classes["initial-text-paragraph"]}>
          Discover the Legacy of the Arabian Horse with AECP: Where Breeding,
          Competition, and Recreation Converge.
        </p>
        <br />
        <div className={classes.bothButton}>
          <a
            href={"about-aecp/membership-application"}
            className={classes.btn}
            style={{ textDecoration: "none" }}
          >
            <span className={classes["btn-text"]}>
              Become a Member <EastIcon sx={{ marginLeft: "5px" }} />
            </span>
          </a>
          <span className={classes["social-handles"]}>
            <a
              href={"https://www.facebook.com/groups/570293584166368/"}
              className={classes["social-link"]}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CarouselSlide;
