import React, { useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import tempHorse from "../../../assets/images/temp-horse.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import logoMask from "../../../assets/images/logo-mask.png";

import classes from "./BreedDetails.module.css";
import { useState } from "react";
import { Link } from "react-router-dom";

import { viewhorse, viewPedigree } from "../../../apis";

import PedigreeModal from "../../Studs/StudElements/PedigreeModal";

const BreedDetails = () => {
  const horseId = useParams().horseId;
  const [pedigree, setPadigree] = useState([]);
  const [HORSE_DATA, setHorseData] = useState([]);
  const [HORSE_OWNERS, setHorseOwners] = useState([]);
  const [showPedigreeModal, setShowPedigreeModal] = useState(false);

  const fetchData = async () => {
    const response = await viewhorse(horseId);
    response
      .json()
      .then((horse) => ({
        horse: horse,
        status: response.status,
      }))
      .then((res) => {
        console.log("horeses", res);
        setHorseData(res.horse.horse);
        setHorseOwners(res.horse.owners);
      });
  };

  const fetchPedigree = async () => {
    const resData = await viewPedigree(horseId);
    const response = await resData.json();
    setPadigree(response);
    console.log(pedigree, 'pedigree');
  };

  useEffect(() => {
    fetchData();
    fetchPedigree();
  }, []);
  return (
    <Fragment>
      <div className={classes["initial-display"]}>
        <div className={classes["initial-text"]}>
          <div className={classes["masked-logo"]}>
            <img src={logoMask} alt="Masked Logo" />
          </div>
          <div className={classes["breed-footer"]}>
            <span className={classes["social-handles"]}>
              <a
                href={"https://www.facebook.com/groups/570293584166368/"}
                style={{ textDecoration: "none", color: "white", zIndex: 1 }}
                target={"_blank"}
              >
                <FacebookIcon />
              </a>
              {/* <YouTubeIcon /> */}
            </span>
            <div>
              <h1>HORSE PROFILE</h1>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.main}>
        <div className={classes["breed-details"]}>
          <div className={classes["breed-image"]}>
            <img
              src={HORSE_DATA.imageUrl != "" ? HORSE_DATA.imageUrl : tempHorse}
              alt={horseId}
            />
          </div>
          <div className={classes["breed-table"]}>
            <h1>{HORSE_DATA.horse_name}</h1>
            <table>
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{HORSE_DATA.horse_name}</td>
                </tr>
                <tr>
                  <td>Foaled:</td>
                  <td>{HORSE_DATA.birth_year}</td>
                </tr>
                <tr>
                  <td>Gender:</td>
                  <td>{HORSE_DATA.gender}</td>
                </tr>
                <tr>
                  <td>Sire:</td>
                  <td>{HORSE_DATA.sire_name}</td>
                </tr>
                <tr>
                  <td>Dam:</td>
                  <td>{HORSE_DATA.dam_name}</td>
                </tr>
                <tr>
                  <td>Breeder:</td>
                  <td>
                    {HORSE_DATA.breeder !== "" && HORSE_DATA.breeder !== null
                      ? HORSE_DATA.breeder
                      : "Not Specified"}
                  </td>
                </tr>
                <tr>
                  <td>Owner(s):</td>
                  <td>
                    {HORSE_OWNERS.map((owner, index) => (
                      <Link
                        to={`/members/${owner?.owner_url}`}
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: 900,
                        }}
                      >
                        {index == HORSE_OWNERS?.length - 1 ? (
                          <span>{owner.owner_name}</span>
                        ) : (
                          <span>{owner.owner_name + ", "}</span>
                        )}
                      </Link>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes["bottom"]}>
            <button onClick={() => setShowPedigreeModal(true)} className={classes.btn}>
              <span className={classes["btn-text"]}>View Pedigree</span>
            </button>
          </div>
        </div>
        {pedigree.pedigree !== undefined ?
          <PedigreeModal
            pedigree={pedigree}
            open={showPedigreeModal}
            setOpen={setShowPedigreeModal}
          />
          :
          ''
        }
      </div>
    </Fragment>
  );
};

export default BreedDetails;
