import React from "react";
import classes from "./AboutAECP.module.css";
import AboutLineColorImg from "../../assets/images/heading-dec.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
const AECPForms = () => {
  const rulesHeading = [{rule_name: 'FORM 1: BROODMARE COVERING', content: `<ol><li style="margin-bottom: 10px;">This return will only be acceptable if the brood mare & the stallion who has covered the mare are registered with the WAHO</li>
  <li style="margin-bottom: 10px;">This return should be sent to the PAKISTAN ARAB HORSE SOCIETY (PAHS) for the period of covering from 1st January to 31st July by 15th August and for the period of covering from 1st August to 31st December by 15th January with registration fee of Rs. 1100/- (Rs. Eleven Hundred) per mare.</li>
  <li style="margin-bottom: 10px;">All columns of the return must be correctly filled and the names of mares and stallions should be written in capital letters.</li>
  <li style="margin-bottom: 10px;">If this return is submitted to the PAHS within 135 days (One hundred & thirty five days) after the last stipulated date for submission of covering return than the registration fee including late fee will be Rs. 2200/- (One thousand) per mare.</li>
  <li style="margin-bottom: 10px;">This return will not be accepted in the PAHS after 135 days (One hundred & thirty five days) from the last stipulated date for submission of covering return.</li>
  <li style="margin-bottom: 10px;">This return will only be acceptable in the PAHS is accompanied by relevant stallion covering return.</li>
  <li style="margin-bottom: 10px;">The date of acceptance of this return in the PAHS will be the one when the specified registration fee is deposited, there is no short coming in filling of the return and it is accompanied with relevant stallion covering return. If an incorrectly filled return or without fee or without stallion covering return is received at the PAHS, the date of receipt or return will not be taken as date of acceptance of the return at the PAHS</li>
  <li style="margin-bottom: 10px;">The name of sire and dam should be written is the blocks provided, each letter of the name should be written in capital word in each block, it the name is in two parts dash should be inserted and like wise dash should be inserted in between name and breed. for example if the name is “ LUCKY BOY” and the breed is ARAB Pakistani it should be written as shown : L U C K Y - B O Y - A R A B</li></ol>
  `, file: 'https://admin.arabians.pk/public/FORM-1.pdf'},
  {rule_name: 'FORM 2: NOTIFICATION OF BIRTH', content: `<ol><li style="margin-bottom: 10px;">All columns of the form must be correctly filled.</li>
  <li style="margin-bottom: 10px;">This form will only be acceptable if you have already registered the covering return of the foal's mother (Form-1) with the Pakistan Arabian Horse Society (PAHS) intime.</li>
  <li style="margin-bottom: 10px;">This form should be sent to the PAHS within 90 days (ninety days) from date of birth of foal with registration fee of RS. 1200/- (Rs. Twelve Hundred) and three colored postcard size photographs of foal that are front pose and two side poses.</li>
  <li style="margin-bottom: 10px;">Is this form is submitted to the PAHS from 91 to 100 days from date of birth of foal then registration fee including late fee will be Rs.900/- from 101 days it will be Rs.2200/- from 111 days to 120 days it will be Rs.3200/-, from 121 days to 130 days it will be Rs.4,600/- from 131 days to 140 days it will be Rs. 6,000/- and from 141 days to 150 days then registration fee including late fee will be Rs.12,000/-.</li>
  <li style="margin-bottom: 10px;">This form will not be accepted after 150 days (one hundred fifty days) of birth of foal therefore foal will not be registered.</li>
  <li style="margin-bottom: 10px;">Description of the horse must be written according to nomenclature / instructions given in rules book of stud book.</li>
  <li style="margin-bottom: 10px;">Authority of Pakistan rules and requirement for registration of horses in Pakistan.</li>
  <li style="margin-bottom: 10px;">Written description should be written in capital letters or typed.</li>
  <li style="margin-bottom: 10px;">Written description and making on the horse diagram must tally.</li>
  <li style="margin-bottom: 10px;">All white marks on the horse should be shown in red ink on the horse diagram.</li>
  <li style="margin-bottom: 10px;">Whorls on the horse must be correctly shown as “X” in blue ink on the horse diagram and their location also correctly described in detail.</li>
  <li style="margin-bottom: 10px;">If there is no white mark on the horse then at least five whorls if existing on the body of the horse must be shown on the horse diagram.</li>
  <li style="margin-bottom: 10px;">The intended brands on the horse must be shown in blue ink on the horse diagram.</li>
  <li style="margin-bottom: 10px;">The name of sire and dam of the horse should be written in the blocks provided, every letter of the name should be written in capital word in each block, if the name is in two parts dash should be inserted in between in a block and likewise dash should be inserted in between name & breed in a block for example if the name is “ LUCKY BOY” and the breed is ARAB Pakistani it should be written as shown : L U C K Y - B O Y - A R A B</li>
  <li style="margin-bottom: 10px;">The date of acceptance of this form in the PAHS will be the one when the specified fee is deposited & there is no shortcoming in filling of the form. If an incorrectly filled form or without fee is received in the PAHS, this date of receipt of form will not be taken as the date of acceptance of the form in the PAHS.</li>
 `, file: 'https://admin.arabians.pk/public/FORM-2.pdf'},
 {rule_name: `FORM 3: VETERINARY CERTIFICATE OF AGE AND MARKINGS FOR FOAL REGISTRATION INSTRUCTIONS`, 
 content: `<ol><li style="margin-bottom: 10px;">All columns of the form must be correctly filled.</li>
 <li style="margin-bottom: 10px;">This form will only be acceptable if you have already registered Notification of Birth (Form-2) of this foal with the Pakistan Arab Horse Society (PAHS) in time. </li>
 <li style="margin-bottom: 10px;">This form should be sent to the PAHS within 350 days (Three hundred and fifty days) of date of birth of foal with registration fee of Rs. 2100/- (Rs. Two Thousand One Hundred only) and tree coloured postcard size photographs of foal that is front pose and tow side poses taken after branding.</li>
 <li style="margin-bottom: 10px;">If this form is submitted to the PAHS forms 351 days to 360 days from the date of birth of foal then registration fee including late fee will be Rs 2500/- from 361 days to 370 days it will be Rs 3000/- from 371 days to 380 days will be Rs.4000/- from 381 days to 390 days it will be Rs 5000/- and from 391 to 400 days then registration fee including late fee will be Rs 10000/-.</li>
 <li style="margin-bottom: 10px;">This form will not be accepted after 400 days (Four hundred days) of birth of foal therefore foal will not be registered.</li>
 <li style="margin-bottom: 10px;">This form must be filled by an authorized PAHS veterinarian who are as follows:
 <ol style="margin: 10px; list-style-type: lower-alpha;"><li style="margin: 10px;">Serving Officer of the rank of Major and above of Remount Veterinary & Form Corp Pakistan Army who is RCVS /DVM / B.VSc or equivalent.</li>
 <li style="margin: 10px;">Serving Director, District Officer Livestock (Animal Health), Deputy District Officer Livestock (Animal health) of Livestock & Dairy Development (Extension) who is MRCVS / DVM / B.V.Sc or equivalent.</li>
 <li style="margin: 10px;">Veterinarian whose name is on the PAHS approved panel having qualification of MRCVS / DVM / B.V.Sc or equivalent.</li></ol></li>
 <li style="margin-bottom: 10px;">Description of the horse must be written according to nomenclature / instructions given in rules books of stud book authority of Pakistan rules and requirement for registration of horses with WAHO.</li>
 <li style="margin-bottom: 10px;">Written description should be written in capital letters or typed.</li>
 <li style="margin-bottom: 10px;">Written description and marking on diagram must tally.</li>
 <li style="margin-bottom: 10px;">All white marks on the horse should be shown in red ink on the horse diagram.</li>
 <li style="margin-bottom: 10px;">The existing brands on the horse must be shown on the horse diagram in the same shape and in blue ink as they are on the horse.</li>
 <li style="margin-bottom: 10px;">Whorls on the horse must be shown as ‘X’ in blue ink and their location correctly shown on the horse diagram sand their location also correctly described in detail.</li>
 <li style="margin-bottom: 10px;">If there is no white mark on the horse then at least five whorls if existing on the body of the horse must be shown on the horse diagram.</li>
 <li style="margin-bottom: 10px;">The name of sire and dam of the horse should be written in the blocks provided, every letter of the name should be written in capital word in each block, if the name is in two parts dash should be inserted in between in a block and likewise dash should be inserted in between name & breed in a block for example if the name is “LUCKY BOY” and the breed is Arab Pakistan it should be written as shown: L U C K Y - B O Y - A R A B</li>
 <li style="margin-bottom: 10px;">The date of acceptance of this form in the PAHS will be the one when the specified tee is deposited and there is no shortcoming in filling the form. If an incorrectly filled form or without fee is received in the PAHS , this date of receipt of form will not be taken as the date of acceptance of the form in the PAHS .</li></ol>
 `, file: 'https://admin.arabians.pk/public/FORM-3.pdf'},
 {rule_name: `FORM 4: APPLICATION FOR REGISTRATION OF BROODMARE / STALLION INSTRUCTIONS`, 
 content: `<ol><li style="margin-bottom: 10px;">Written description should be typed or written in block capitals.</li>
 <li style="margin-bottom: 10px;">Written description and diagram should agree.</li>
 <li style="margin-bottom: 10px;">All white markings should be hatched in red.</li>
 <li style="margin-bottom: 10px;">Whorls must be shown thus ‘X’ and described in the form in detail.</li></ol>
 `, file: 'https://admin.arabians.pk/public/FORM-4.pdf'},
 {rule_name: `FORM 5: STALLION COVERING RETURN`, 
 content: `<ol><li style="margin-bottom: 10px;">This return will only be acceptable if you already have a registered brood mare who is covered and a stallion who has covered with the Word Arabian Horse Organization (WAHO).</li>
 <li style="margin-bottom: 10px;">This return should be sent to the Pakistan Arabian Horse Society (PAHS) for the period of covering from 1st January to 31 July by 15 August and for the period of covering from 1st August to 31 December by 15th January.</li>
 <li style="margin-bottom: 10px;">This return will be submitted separately for each stallion.</li>
 <li style="margin-bottom: 10px;">The name of mares and stallions should be written in capital letters, in the block provided.</li>
 <li style="margin-bottom: 10px;">If this return is submitted to the PAHS within 135 days (One hundred and thirty five days) after the last stipulated date for submission of covering return then the current registration fee including current late registration fee will have to be remitted.</li>
 <li style="margin-bottom: 10px;">This return will not be accepted in the PAHS after 135 days (One hundred and thirty five days) from the last stipulated date for submission of the covering return.</li>
 <li style="margin-bottom: 10px;">This return should be accompanied with the relevant brood mare covering return.</li>
 <li style="margin-bottom: 10px;">All columns of this return must be correctly filed with special care of spelling of the names.</li>
 <li style="margin-bottom: 10px;">The date of acceptance of this return in the PAHS will be the one when the specified current registration fee is deposited, there is no short filing of the return and it is accompanied with relevant brood mare covering return. If an incorrectly filled return or without fee or without brood mare covering return is received at the PAHS, this date of receipt of return will not be taken as date of acceptance to the return at the PAHS.</li>
 <li style="margin-bottom: 10px;">Death of stallion must be reported to the PAHS within 15 days (forty five days) failing which late fee of Rs. 1000 (One thousand) will be charged for registration of death</li>
 <li style="margin-bottom: 10px;">The name of sire and dam of the horse should be written in the blocks provided, every letter of the name should be written in capital word in each block, if the name is in two parts dash should be inserted in between in a block and likewise dash should be inserted in between name & breed in a block for example if the name is “LUCKY BOY” and the breed is Arab Pakistan it should be written as shown: L U C K Y - B O Y - A R A B</li></ol>
 `, file: 'https://admin.arabians.pk/public/FORM-5.pdf'}];

  return (
    <div>
      <div className={classes["HistoryAECP"]}>
          <div>
            <div style={{ float: "left" }}>
              <h2 className={classes["AboutHeading"]}>Horse Registration Forms</h2>
            </div>
            <div style={{ float: "left" }}>
              <img
                src={AboutLineColorImg}
                alt="about img"
                style={{}}
                className={classes.clublineImg}
              />
            </div>
            <div className={classes["clear"]}></div>
            <hr></hr>
          </div>
          <div className={classes["newsMainDiv"]} style={{ paddingTop: 10 }}>
            <div className="">
              <h2 style={{fontFamily: "Crimson Text", fontStyle: 'normal'}}>Register Your Foal/Horse:</h2>
                <ul style={{listStyle: 'none', fontFamily: "Crimson Text", fontStyle: 'normal'}}>
                  <li><span style={{color: 'red', fontWeight: 900}}>*</span> <strong>Browse and download the necessary forms listed below for registration.</strong></li>
                  <li><span style={{color: 'red', fontWeight: 900}}>*</span> <strong>Follow the provided instructions to ensure accurate form completion.</strong></li>
                  <li><span style={{color: 'red', fontWeight: 900}}>*</span> <strong>Take note of submission deadlines as mentioned in the instructions.</strong></li>
                  <li><span style={{color: 'red', fontWeight: 900}}>*</span> <strong>Once filled, please send the completed form to Mona Depot.</strong></li>
                  <li><span style={{color: 'red', fontWeight: 900}}>*</span> <strong>Need help? Reach out to AECP for further assistance.</strong></li>
                </ul>
            </div>
          <Accordion
                        preExpanded={["a"]}
                        style={{ border: "none", paddingTop: 20 }}
                      >
                        {rulesHeading?.map((Rulesname, index) => {
                          // console.log(index, "indesx.......")
                          // console.log(Rulesname, "NAME.......")
                          return (
                            <AccordionItem
                              key={index}
                              uuid={
                                index === 0
                                  ? "a"
                                  : index === 1
                                    ? "b"
                                    : index === 2
                                      ? "c"
                                      : index === 3
                                        ? "d"
                                        : index === 4
                                          ? "e"
                                          : index === 5
                                            ? "f"
                                              : index === 6
                                              ? "g"
                                                : index === 7
                                                ? "h"
                                                  : index === 8
                                                  ? "i"
                                            : ""
                              }
                              className={classes["AccordionTitle"]}
                            >
                              <AccordionItemHeading>
                                <AccordionItemButton
                                  className={classes["AccordionItemBtn"]}
                                >
                                  <div style={{paddingBottom: "40px", paddingTop: "10px"}}>
                                    <span className={classes["FormTitle"]}>{Rulesname.rule_name}</span>
                                    <a
                                      href={Rulesname.file}
                                      target="_blank"
                                      type="button"
                                      className={classes["DownBtn"]}
                                      style={{textDecoration: 'none', float: 'right', padding: 0}}
                                    >
                                      Download Form
                                    </a>
                                  </div>
                                </AccordionItemButton>
                              </AccordionItemHeading>

                              <AccordionItemPanel>
                                <p
                                  className={classes["AccordionText"]}
                                  dangerouslySetInnerHTML={{
                                    __html: Rulesname.content
                                      ?.toString()
                                      .replace(/&lt;/g, "<")
                                      .replace(/&gt;/g, ">")
                                      .replace(/&quot;/g, '"')
                                      .replace(/&#39;/g, "'")
                                      .replace(/&amp;/g, "&"),
                                  }}
                                ></p>
                              </AccordionItemPanel>
                            </AccordionItem>
                          );
                        })}
                      </Accordion>
          </div>
      </div>
    </div>
  );
};

export default AECPForms;
