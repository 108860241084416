import React, { useState, useEffect } from "react";
import picture from "../../../assets/images/home_page_banner.JPEG";
import classes from "./HeroSection.module.css";
import logoMask from "../../../assets/images/image.png";

const HeroSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className={classes["hero-section"]}>
      <div className={classes.parent}>
        {windowWidth > 500 ? (
          <></>
        ) : (
          <>
            <div className={classes["masked-logo"]}>
              <img src={logoMask} alt="" />
            </div>
          </>
        )}
        <div className={classes.text}>
          <h1>Arabian Equestrian Club of Pakistan</h1>
          <h2>Our Statement</h2>
          <p>
            AECP - Arabian Equestrian Club of Pakistan, is a registered Arabian
            Horse breed association focused on meeting the breeding, competitive
            and recreational interests of Arabian horse owners across Pakistan.
            Our mission encompasses promoting the growth and recognition of this
            renowned breed through ethical breeding, welfare, and sportsmanship
            while upholding the highest of standards in all aspects. At AECP we
            hope to empower our members by providing educational opportunities
            through workshops and seminars, organizing prestigious shows and
            events, and establishing international partnerships. AECP ultimately
            aims to establish a thriving and inclusive community of Arabian
            horse enthusiasts in Pakistan - ensuring a fulfilling equestrian
            experience and leaving a lasting legacy for generations to come.
          </p>
        </div>
        <div className={classes.image}>
          <img src={picture} alt="Horse Hero" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
