import React, { useState, useEffect } from "react";
import Events from "./LandingElements/Events";
import PrevEvents from "./LandingElements/PrevEvents";
import News from "./LandingElements/News";
import Carousel from "react-material-ui-carousel";
import ResponsiveContent from "./ResponsiveContent";
import CarouselSlide from "./Carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import classes from "./Landing.module.css";
import { allevents, getstuds } from "../../apis";
import HeroSection from "./LandingElements/HeroSection";
import { Link } from "react-router-dom";

export const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;

  return (
    <div className={classes["custom-arrow"]} onClick={() => onClick()}>
      <ArrowForwardIcon />
    </div>
  );
};

export const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;

  return (
    <div
      style={{
        right: "40px",
      }}
      className={classes["custom-arrow"]}
      onClick={() => onClick()}
    >
      <ArrowBackIcon />
    </div>
  );
};

const Landing = () => {
  const [STUD_DATA, setstuds] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const [UPCOMING_EVENT_DATA, setupcoming] = useState([]);
  const [PREVIOUS_EVENT_DATA, setprevious] = useState([]);

  const fetchEvents = async () => {
    const response = await allevents();
    response
      .json()
      .then((data) => ({
        data: data,
        status: response.status,
      }))
      .then((res) => {
        setupcoming(res.data.data.upcoming);
        setprevious(res.data.data.previous);
      });
  };

  const fetchStuds = async () => {
    const response = await getstuds();
    response
      .json()
      .then((studs) => ({
        studs: studs,
        status: response.status,
      }))
      .then((res) => {
        setstuds(res.studs.studs);
      });
  };

  useEffect(() => {
    fetchEvents();
    fetchStuds();

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={classes.landing}>
      <div className={classes["initial-display"]}>
        {/* {windowWidth > 767 ? <CarouselSlide /> : <ResponsiveContent />} */}
        <CarouselSlide />
      </div>
      <HeroSection />

      <div className={classes.container}>
        <section
          className={classes["studs-display"]}
          style={{ display: "none" }}
        >
          <Carousel
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            swipeable={false}
            draggable={false}
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
          >
            {STUD_DATA?.length > 0 ? (
              <div
                className={classes["stud-cards"]}
                style={{ display: "none" }}
              >
                <div>
                  <div className={classes["stud-data-contents"]}>
                    <div className={classes["stud-data-main-heading"]}>
                      <h2 className={classes["stud-head"]}>Studs</h2>
                    </div>
                    {STUD_DATA.map((stud) => (
                      <p>
                        <span className={classes["stud-data-content"]}>
                          <Link to={`/studs/${stud.friendly_URL}`}>
                            {stud.horse_name} -{" "}
                            {new Date(stud.birth_year).getFullYear()}
                          </Link>
                        </span>
                      </p>
                    ))}
                  </div>
                </div>
                <div>
                  <div className={classes["stud-data-contents"]}>
                    <div className={classes["stud-data-main-heading"]}>
                      <h2 className={classes["stud-head"]}>Events</h2>
                    </div>

                    {PREVIOUS_EVENT_DATA.map((event) => (
                      <p>
                        <span className={classes["stud-data-content"]}>
                          <Link to={`/events/${event.id}`}>
                            {event.title} - {event.city}
                          </Link>
                        </span>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </Carousel>
        </section>
        <News />
      </div>
    </div>
  );
};

export default Landing;
