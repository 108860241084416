import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "react-modern-drawer";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import horseIcon from "../../assets/images/horseIcon.png";

import classes from "./Drawer.module.css";
import "react-modern-drawer/dist/index.css";

const FloatingDrawer = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [activePage, setActivePage] = useState("");
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const pageChangeHandler = (event) => {
    const page = event.currentTarget.id;
    setActivePage(page);

    navigate(`${page}`);
  };

  return (
    <Fragment className={classes["drawer-parent"]}>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        className={classes.drawer}
      >
        <div
          className={`${classes["drawer-call"]} ${isOpen ? classes.open : ""}`}
        >
          <IconButton onClick={toggleDrawer} aria-label="delete">
            <img src={horseIcon} alt="Horse" />
            {/* {isOpen ? (
      <ArrowForwardIosIcon />
    ) : (
      <ArrowBackIosNewIcon />
    )} */}
          </IconButton>
        </div>

        <section className={`${classes["view-controller"]}`}>
          <div
            id="/"
            style={{ borderTopRightRadius: "15px" }}
            onClick={pageChangeHandler}
            className={activePage === "/" && classes.active}
          >
            Home
          </div>
          <div
            id="about-aecp/news"
            onClick={pageChangeHandler}
            className={activePage === "news" && classes.active}
          >
            News & Updates
          </div>
          <div
            id="/blogs"
            onClick={pageChangeHandler}
            className={activePage === "blogs" && classes.active}
          >
            Blogs
          </div>
          <div
            id="/gallery"
            onClick={pageChangeHandler}
            className={activePage === "gallery" && classes.active}
          >
            Gallery
          </div>
        </section>
      </Drawer>
    </Fragment>
  );
};

export default FloatingDrawer;
