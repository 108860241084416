import React from "react";
import BreedList from "./BreedElements/BreedList";
import BreedListDetails from "./BreedElements/BreedListDetails";
import { IconButton, InputBase, Paper } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SearchIcon from "@mui/icons-material/Search";
import logoMask from "../../assets/images/logo-mask.png";
import horse from "../../assets/images/horse.png";

import classes from "./BreedDatabase.module.css";
import { useState } from "react";

const BreedDatabase = ({ showBreedListDetails }) => {
  const currentPath = window.location.pathname;
  const pathParts = currentPath.split("/");
  const lastSlug = pathParts[pathParts.length - 1];
  const [searchQuery, setSearchQuery] = useState("");
  const [queryToSend, setQueryToSend] = useState("");

  const handleSearchClick = () => {
    setQueryToSend(searchQuery);
  };

  const resetSearchQuery = () => {
    setSearchQuery("");
    setQueryToSend("");
  };
  return (
    <div className={classes["breed-database"]}>
      <div className={classes["initial-display"]}>
        <div className={classes["initial-text"]}>
          <div className={classes["masked-logo"]}>
            <img src={logoMask} alt="Masked Logo" />
          </div>
          <h1>AECP DATABASE</h1>
          <p
            style={{
              fontSize: "20px",
              textAlign: "center",
              marginTop: "-1rem",
            }}
          >
            An approved directory of{" "}
            {lastSlug !== "breed-database"
              ? lastSlug.replace("%20", " ") + " horses"
              : "horse breeds"}{" "}
            registered in the Arabian Equestrian Club of Pakistan (AECP)
          </p>
          <div className={classes["breed-footer"]}>
            <span className={classes["social-handles"]}>
              <a
                href={"https://www.facebook.com/groups/570293584166368/"}
                style={{ textDecoration: "none", color: "white", zIndex: 1 }}
                target={"_blank"}
              >
                <FacebookIcon />
              </a>
            </span>
            {lastSlug !== "breed-database" ? (
              <div>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: "100px",
                    width: 300,
                  }}
                >
                  <IconButton
                    sx={{ p: "10px", color: "white" }}
                    aria-label="menu"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1, color: "white" }}
                    placeholder="Search Horse Name"
                    inputProps={{ "aria-label": "search google maps" }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Paper>
                <button onClick={handleSearchClick}>SEARCH</button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {showBreedListDetails ? (
        <BreedListDetails
          itemsPerPage={12}
          query={queryToSend}
          resetSearchQuery={resetSearchQuery}
        />
      ) : (
        <BreedList />
      )}
    </div>
  );
};

export default BreedDatabase;
