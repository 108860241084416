import React, { useState, useContext } from "react";
import { Button, Drawer } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/images/logo.png";
import classes from "./Navbar.module.css";
import { Link, useNavigate } from "react-router-dom";
import SignInModal from "../Auth/SignInModal";
import { GlobalContext } from "../../context/store";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MobileList from "./MobileNavbar";

const Navbar = (user) => {
  // Temp
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpen = () => setOpen((prevState) => !prevState);
  const handleClose = () => setOpen(false);

  const { unsetAuthUser } = useContext(GlobalContext);

  console.log(user);
  // Temp
  const [drawerState, setDrawerState] = useState(false);
  const toggleDrawer = () => {
    setDrawerState((prevState) => !prevState);
  };

  const navbarNavigationHandler = (event) => {
    navigate(`${event?.target?.id}`);
  };
  const logoutUserHandler = () => {
    unsetAuthUser();
    navigate("/");
  };
  return (
    <div className={classes.navbar}>
      <img
        onClick={navbarNavigationHandler}
        id=""
        src={logo}
        alt="AECP logo"
        title="AECP logo"
        style={{ cursor: "pointer" }}
      />
      <div className={classes["hidden-mobile"]}>
        <ul className={classes["nested-dropdowns"]}>
          <li>
            <div className={classes["nested-dropdowns__item"]}>ABOUT</div>
            <ul style={{ width: "180px", left: "-70px" }}>
              <li style={{ width: "150px", marginTop: "10px" }}>
                <Link to="/about-aecp">About AECP</Link>
              </li>
              <li style={{ width: "150px" }}>
                <hr></hr>
              </li>
              <li style={{ width: "150px", marginBottom: "10px" }}>
                <Link to="/about-the-breed">About The Breed</Link>
              </li>
            </ul>
          </li>
          <li>
            <div className={classes["nested-dropdowns__item"]}>
              AECP DATABASE
            </div>
            <ul style={{ width: "220px", left: "-45px" }}>
              <li style={{ width: "200px", marginTop: "10px" }}>
                <Link to="/breed-database">AECP Registered Horses</Link>
              </li>
              <li style={{ width: "200px" }}>
                <hr></hr>
              </li>
              <li style={{ width: "200px" }}>
                <Link to="/studs">Stallions at Stud</Link>
              </li>
              <li style={{ width: "200px" }}>
                <hr></hr>
              </li>
              <li style={{ width: "200px", marginBottom: "10px" }}>
                <Link to="/horses-for-sale">Horses for Sale</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/members">MEMBERS</Link>
          </li>
          <li>
            <Link to="/farms">FARMS</Link>
          </li>
          <li>
            <Link to="/events">EVENTS</Link>
          </li>
        </ul>
        <ul style={{ display: "none" }}>
          <li>ABOUT THE CLUB</li>
          <li>
            <Link to="/about-aecp/AECPteam">AECP TEAM</Link>
          </li>
          <li>
            <Link to="/breed-database">AECP Database</Link>
          </li>
          <li>
            <Link to="/studs">STUDS</Link>
          </li>
          <li>
            <Link to="/members">MEMBERS</Link>
          </li>
          <li>
            <Link to="/farms">FARMS</Link>
          </li>
        </ul>
      </div>
      {user?.user?.id ? (
        <div className={classes["logged-in-buttons"]}>
          <button
            className={`${classes["inverse-button"]} ${classes["hidden-mobile"]}`}
          >
            <Link to="/member-profile">MY PROFILE</Link>
          </button>
          <button
            onClick={logoutUserHandler}
            className={`${classes["inverse-button"]} ${classes["hidden-mobile"]}`}
          >
            <Link to="/member-profile">LOGOUT</Link>
          </button>
        </div>
      ) : (
        <div className={classes["logged-in-buttons"]}>
          <button
            onClick={handleOpen}
            className={`${classes["inverse-button"]} ${classes["hidden-mobile"]}`}
          >
            LOGIN
          </button>
          <Link to="/sign-up">
            <button
              className={`${classes["inverse-button"]} ${classes["hidden-mobile"]}`}
            >
              SIGN UP
            </button>
          </Link>
        </div>
      )}
      <div className={classes.dropdown}>
        <Button
          style={{
            padding: 0,
            color: "white",
          }}
          onClick={toggleDrawer}
        >
          <MenuIcon fontSize="medium" />
        </Button>
        <Drawer
          PaperProps={{
            sx: {
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            },
          }}
          anchor={"top"}
          open={drawerState}
          onClose={toggleDrawer}
        >
          {/* {MobileList(
            toggleDrawer,
            navbarNavigationHandler,
            user,
            handleOpen,
            logoutUserHandler
          )} */}

          <MobileList
            toggleDrawer={toggleDrawer}
            navbarNavigationHandler={navbarNavigationHandler}
            user={user}
            handleOpen={handleOpen}
            logoutUserHandler={logoutUserHandler}
          />

          {/* {MobileList({
            toggleDrawer,
            navbarNavigationHandler,
            user,
            handleOpen,
            logoutUserHandler,
          })} */}
        </Drawer>
      </div>
      <SignInModal open={open} handleClose={handleClose} />
    </div>
  );
};
export default Navbar;
