import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
} from "@mui/material";
import { Link } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import logo from "../../assets/images/logo.png";
import classes from "./Navbar.module.css";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const MobileList = ({
  toggleDrawer,
  navbarNavigationHandler,
  user,
  handleOpen,
  logoutUserHandler,
}) => {
  const [aboutOpen, setAboutOpen] = useState(false);
  const [aecpDBOpen, setaecpDBOpen] = useState(false);

  const toggleAbout = (event) => {
    event.stopPropagation();

    setAboutOpen((prevOpen) => !prevOpen);
  };

  const toggleaecpDB = (event) => {
    event.stopPropagation();

    setaecpDBOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
      id={classes["dropdown-box"]}
    >
      <div
        onClick={navbarNavigationHandler}
        id=""
        className={classes["dropdown-logo"]}
      >
        <img src={logo} alt="AECP logo" title="AECP logo" />
        <span style={{ color: "white" }}>
          <ClearIcon />
        </span>
      </div>
      <List className={classes["dropdown-list"]}>
        <ListItem disablePadding>
          <ListItemButton onClick={toggleAbout}>
            <ListItemText primary="About" />
            {aboutOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={aboutOpen} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            className={classes["dropdown-list-child"]}
          >
            <Link to="/about-aecp">
              <ListItemButton>
                <ListItemText primary="About AECP" />
              </ListItemButton>
            </Link>
            <Link to="/about-the-breed">
              <ListItemButton>
                <ListItemText primary="About The Breed" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton onClick={toggleaecpDB}>
            <ListItemText primary="AECP Database" />
            {aecpDBOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={aecpDBOpen} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            className={classes["dropdown-list-child"]}
          >
            <Link to="/breed-database">
              <ListItemButton>
                <ListItemText primary="AECP Registered Horses" />
              </ListItemButton>
            </Link>
            <Link to="/studs">
              <ListItemButton>
                <ListItemText primary="Stallions at Stud" />
              </ListItemButton>
            </Link>
            <Link to="/horses-for-sale">
              <ListItemButton>
                <ListItemText primary="Horses For Sale" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Link to="/members">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Members" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/farms">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Farms" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to="/events">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Events" />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
      <List className={classes["dropdown-list"]}>
        <ListItem disablePadding>
          {user?.user?.length > 0 ? (
            <>
              <Link to="/member-profile">
                <ListItemButton className={classes["dropdown-buttons"]}>
                  <button
                    onClick={handleOpen}
                    className={classes["inverse-button"]}
                  >
                    MY PROFILE
                  </button>
                </ListItemButton>
              </Link>

              <ListItemButton className={classes["dropdown-buttons"]}>
                <button
                  onClick={logoutUserHandler}
                  className={classes["inverse-button"]}
                >
                  LOGOUT
                </button>
              </ListItemButton>
            </>
          ) : (
            <>
              <ListItemButton className={classes["dropdown-buttons"]}>
                <button
                  onClick={handleOpen}
                  className={classes["inverse-button"]}
                >
                  Log In
                </button>
              </ListItemButton>
              <Link to="/sign-up">
                <ListItemButton className={classes["dropdown-buttons"]}>
                  <button className={classes["inverse-button"]}>Sign Up</button>
                </ListItemButton>
              </Link>
            </>
          )}
        </ListItem>
      </List>
    </Box>
  );
};

export default MobileList;
