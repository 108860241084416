import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import tempMemberCard from "../../../assets/images/temp-member-card.png";
import tempMember from "../../../assets/images/temp-member.png";
import tempFarm from "../../../assets/images/temp-farm.png";
import tempHorse from "../../../assets/images/horse.png";
import { InputBase, IconButton, Paper } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LockIcon from "@mui/icons-material/Lock";
import SearchIcon from "@mui/icons-material/Search";
import logoMask from "../../../assets/images/logo-mask.png";
import { viewmember } from "../../../apis";
import classes from "./MemberDetails.module.css";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Stack, Switch, Typography } from "@mui/material";

const MemberDetails = () => {
  const memberId = useParams().memberId;
  const [farmActive, setFarmActive] = useState(true);
  const [horseActive, setHorseActive] = useState(true);
  const [saleActive, setSaleActive] = useState(true);
  const [MEMBER_DATA, setMember] = useState(null);
  const [MEMBER_FARM_DATA, setFarm] = useState([]);
  const [MEMBER_HORSE_DATA, setHorse] = useState([]);
  const [gender_filter, setFilter] = useState(false);

  const [filly, setFilly] = useState(false);
  const [colt, setColt] = useState(false);
  const [stallion, setStallion] = useState(false);
  const [mare, setMare] = useState(false);
  const [all, setAll] = useState(true);

  const fetchData = async () => {
    try {
      const response = await viewmember(memberId);
      const responseData = await response.json();
      // console.log(responseData);
      if (response.status === 200) {
        const { member } = responseData;
        console.log("response ", responseData);
        const profile = member.profile[0];
        setMember(profile);
        setFarm(member.stud_farms);
        setHorse(member.total_horses);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleActive = (e) => {
    if (e.target.id == "farmActive") {
      setFarmActive(true);
      setHorseActive(false);
      setSaleActive(false);
      setFilter(false);
    } else if (e.target.id == "horseActive") {
      setFarmActive(false);
      setHorseActive(true);
      setSaleActive(false);
      setFilter(false);
    } else if (e.target.id == "saleActive") {
      setFarmActive(false);
      setHorseActive(false);
      setSaleActive(true);
      setFilter(false);
    }
  };

  const handleGender = (gender) => 
  {
    if(gender == 'filly')
    {
      setFilly(true);
      setColt(false);
      setStallion(false);
      setMare(false);
      setAll(false);
    }
    else if(gender == 'colt')
    {
      setFilly(false);
      setColt(true);
      setStallion(false);
      setMare(false);
      setAll(false);
    }
    else if(gender == 'stallion')
    {
      setFilly(false);
      setColt(false);
      setStallion(true);
      setMare(false);
      setAll(false);
    }
    else if(gender == 'mare')
    {
      setFilly(false);
      setColt(false);
      setStallion(false);
      setMare(true);
      setAll(false);
    }
    else
    {
      setFilly(false);
      setColt(false);
      setStallion(false);
      setMare(false);
      setAll(true);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.main}>
      <div className={classes["initial-display"]}>
        <div className={classes["initial-text"]}>
          <div className={classes["masked-logo"]}>
            <img src={logoMask} alt="Masked Logo" />
          </div>
          <div className={classes["breed-footer"]}>
            <span className={classes["social-handles"]}>
              <a
                href={"https://www.facebook.com/groups/570293584166368/"}
                style={{ textDecoration: "none", color: "white", zIndex: 1 }}
                target={"_blank"}
              >
                <FacebookIcon />
              </a>
            </span>
            <div>
              <h1>MEMBER PROFILE</h1>
              {/* <p>Breed Database / Member Details</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className={classes["breed-details"]}>
        <div className={classes["breed-image"]}>
          {MEMBER_DATA?.photo !== "" && MEMBER_DATA?.photo !== null ? (
            <img
              src={
                "https://admin.arabians.pk/public/members/profile_pic/" +
                MEMBER_DATA?.photo
              }
            />
          ) : (
            <img src={"https://admin.arabians.pk/public/img/blank.jpg"} />
          )}
        </div>
        <div className={classes["breed-table"]}>
          <h1>{MEMBER_DATA?.first_name + " " + MEMBER_DATA?.last_name}</h1>
          <table style={{ borderSpacing: "0px auto" }}>
            <tbody>
              <tr>
                <td>Membership No:</td>
                <td>{MEMBER_DATA?.membership_no}</td>
              </tr>
              <tr>
                <td>Address:</td>
                <td>
                  {" "}
                  {MEMBER_DATA?.check_address === "Hide" ? (
                    <LockIcon fontSize="small" />
                  ) : MEMBER_DATA?.address != "" ? (
                    MEMBER_DATA?.address
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>
                  {" "}
                  {MEMBER_DATA?.check_email === "Hide" ? (
                    <LockIcon fontSize="small" />
                  ) : MEMBER_DATA?.email != "" ? (
                    MEMBER_DATA?.email
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
              <tr>
                <td>Phone No:</td>
                <td>
                  {MEMBER_DATA?.check_phone === "Hide" ? (
                    <LockIcon fontSize="small" />
                  ) : MEMBER_DATA?.phone != "" ? (
                    MEMBER_DATA?.phone
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className={classes["member-cards"]}>
            <div className={classes["rounded-buttons"]}>
              <button id={"farmActive"} onClick={handleActive}>
                Farm
              </button>
              <button id={"horseActive"} onClick={handleActive}>
                Horses
              </button>
              <button id={"saleActive"} onClick={handleActive}>
                Horses For Sale
              </button>
              {(horseActive || saleActive) && !farmActive ? (
                <Stack direction="row" spacing={2} alignItems="center">
                  <Stack>
                    <Switch
                      checked = {all}
                      onChange={() => handleGender('all')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>All Horses</Typography>
                  </Stack>
                  <Stack>
                    <Switch
                      checked = {filly}
                      onChange={() => handleGender('filly')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Fillies</Typography>
                  </Stack>
                  <Stack>
                    <Switch
                      checked = {colt}
                      onChange={() => handleGender('colt')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Colts</Typography>
                  </Stack>
                  <Stack>
                    <Switch
                      checked = {stallion}
                      onChange={() => handleGender('stallion')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Stallions</Typography>
                  </Stack>
                  <Stack>
                    <Switch
                      checked = {mare}
                      onChange={() => handleGender('mare')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Mares</Typography>
                  </Stack>
                </Stack>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={classes["member-cards"]}>
            {farmActive && MEMBER_FARM_DATA?.length > 0 ? (
              MEMBER_FARM_DATA.map((farm) => (
                <Link
                  to={`/farms/${farm?.friendly_URl}`}
                  className={classes.linkStyle}
                >
                  <div className={classes["member-card"]}>
                    <img src={tempFarm} alt={tempMemberCard} />
                    <h2>{farm?.stud_farm_name}</h2>
                  </div>
                </Link>
              ))
            ) : horseActive && MEMBER_HORSE_DATA?.length > 0 ? (
              MEMBER_HORSE_DATA.map((horse) =>
                filly && horse?.gender2 < 4 && horse.sex === "Female" ? (
                  <Link
                    to={`/breed-database/${horse?.breed}/${horse?.friendly_URL}`}
                    className={classes.linkStyle}
                  >
                    <div className={classes["member-card"]}>
                      <img src={tempHorse} alt={tempMemberCard} />
                      <h2>{horse?.horse_name}</h2>
                    </div>
                  </Link>
                ) : mare && horse?.gender2 >= 4 && horse.sex === "Female" ? (
                  <Link
                    to={`/breed-database/${horse?.breed}/${horse?.friendly_URL}`}
                    className={classes.linkStyle}
                  >
                    <div className={classes["member-card"]}>
                      <img src={tempHorse} alt={tempMemberCard} />
                      <h2>{horse?.horse_name}</h2>
                    </div>
                  </Link>
                ) : colt && horse?.gender2 < 4 && horse.sex === "Male" ? (
                  <Link
                    to={`/breed-database/${horse?.breed}/${horse?.friendly_URL}`}
                    className={classes.linkStyle}
                  >
                    <div className={classes["member-card"]}>
                      <img src={tempHorse} alt={tempMemberCard} />
                      <h2>{horse?.horse_name}</h2>
                    </div>
                  </Link>
                ) : stallion && horse?.gender2 >= 4 && horse.sex === "Male" ? (
                  <Link
                    to={`/breed-database/${horse?.breed}/${horse?.friendly_URL}`}
                    className={classes.linkStyle}
                  >
                    <div className={classes["member-card"]}>
                      <img src={tempHorse} alt={tempMemberCard} />
                      <h2>{horse?.horse_name}</h2>
                    </div>
                  </Link>
                ) : all == true ? (
                  <Link
                    to={`/breed-database/${horse?.breed}/${horse?.friendly_URL}`}
                    className={classes.linkStyle}
                  >
                    <div className={classes["member-card"]}>
                      <img src={tempHorse} alt={tempMemberCard} />
                      <h2>{horse?.horse_name}</h2>
                    </div>
                  </Link>
                )
                :
                (<></>)
              )
            ) : saleActive && MEMBER_HORSE_DATA?.length > 0 ? (
              MEMBER_HORSE_DATA.map((horse) =>
                horse?.for_sale === "Yes" &&
                filly && horse?.gender2 < 4 &&
                horse?.sex === "Female" ? (
                  <Link
                    to={`/breed-database/${horse?.breed}/${horse?.friendly_URL}`}
                    className={classes.linkStyle}
                  >
                    <div className={classes["member-card"]}>
                      <img src={tempHorse} alt={tempMemberCard} />
                      <h2>{horse?.horse_name}</h2>
                    </div>
                  </Link>
                ) : horse?.for_sale === "Yes" &&
                  mare && horse?.gender2 >= 4 &&
                  horse?.sex === "Male" ? (
                  <Link
                    to={`/breed-database/${horse?.breed}/${horse?.friendly_URL}`}
                    className={classes.linkStyle}
                  >
                    <div className={classes["member-card"]}>
                      <img src={tempHorse} alt={tempMemberCard} />
                      <h2>{horse?.horse_name}</h2>
                    </div>
                  </Link>
                ) : (
                  <></>
                )
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* <div className={classes["bottom"]}>
          <button className={classes.btn}>
            <span className={classes["btn-text"]}>View Pedigree</span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default MemberDetails;
