// import React from "react";
import { Modal } from "@material-ui/core";
import { Box } from "@mui/material";
import pedigreeImage from "../../../assets/images/pedigree-image.png";
// hamza
import React, { useEffect, useState } from "react";
import "./Pedigree.css";

import { useParams } from "react-router-dom";

const placeHolder = "https://illustoon.com/photo/3047.png";

const style = {
  position: "absolute",
  // top: "50%",
  marginTop: "375px",
  left: "50%",
  transform: "translate(-50%, -52%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 4,
  width: "90%",
  borderRadius: 5,
};

const PedigreeModal = ({ pedigree, open, setOpen }) => {
  const [padigree, setPadigree] = useState([]);
  // console.log(pedigree?.gen1?.father, "helooo-----")
  const horseId = useParams().horseId;
  const imageURL = `https://admin.rottweiler.pk/public/dog_images/`;
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* <img src={pedigreeImage} alt="" /> */}

        <div id="tree">
          <div className="branch">
            <div className="entry">
              <span style={{ background: '#ccccff', height: '400px', top: '23%', fontSize: '18px', alignItems: 'center', display: 'grid' }} id="gen1" className={'male'}>
                <div id={padigree && padigree?.father_id}>
                  <div className="paddigery-text py-2">
                    {/* {padigree?.father ? padigree?.father?.slice(0, 20) + "...." : "Unkown"} */}

                    {pedigree?.pedigree?.gen1[0]?.father.length === 0 ? (
                      <h5>Unknown</h5>
                    ) : (
                      <>
                        <h6 className="font-weight-bold" >
                          {pedigree?.pedigree?.gen1[0]?.father[0].horse_name}
                        </h6>
                        {pedigree?.pedigree?.gen1[0]?.father[0]?.dob != null ? 
                        <h6 className="font-weight-bold" >
                          {'b. '+pedigree?.pedigree?.gen1[0]?.father[0]?.dob}
                        </h6>
                        :
                        ''
                        }
                        {pedigree?.pedigree?.gen1[0]?.father[0]?.regestration_no != null ? 
                        <h6 className="font-weight-bold" >
                          {pedigree?.pedigree?.gen1[0]?.father[0]?.regestration_no}
                        </h6>
                        :
                        ''
                        }
                      </>
                    )}
                  </div>
                </div>
              </span>
              <div className="branch" style={{ background: "" }}>
                <div className="entry" >
                  <span style={{ top: "46%", height: '200px', background: '#ccccff', fontSize: '16px', alignItems: 'center', display: 'grid', fontSize: '16px'}} >
                    <div
                      style={{ zIndex: 1000 }}
                      id={padigree && padigree?.father_id}
                    >
                      {/* <div className="d-flex justify-content-between align-items-center"> */}
                      <div
                        className=""
                        style={{
                          display: "flex",
                          
                          alignItems: "center",
                        }}
                      >
                        <div className="paddigery-text py-2">
                          {pedigree?.pedigree?.gen2[0]?.gfather1.length === 0 ? (
                            <h5>Unknown</h5>
                          ) : (
                            pedigree?.pedigree?.gen2[0]?.gfather1.map(
                              (gfather1Data, i) => (
                                <h6 className="" key={i}>
                                  {gfather1Data.horse_name}
                                </h6>
                              )
                            )
                          )}
                          {pedigree?.pedigree?.gen2[0]?.gfather1[0]?.dob != null ? 
                            <h6 className="font-weight-bold" >
                              {'b. '+pedigree?.pedigree?.gen2[0]?.gfather1[0]?.dob}
                            </h6>
                          :
                          ''
                          }
                          {pedigree?.pedigree?.gen2[0]?.gfather1[0]?.regestration_no != null ? 
                            <h6 className="font-weight-bold" >
                              {pedigree?.pedigree?.gen2[0]?.gfather1[0]?.regestration_no}
                            </h6>
                          :
                          ''
                          }
                        </div>
                      </div>
                    </div>
                  </span>
                  <div className="branch">
                    <div className="entry">
                      <span style={{ top: '92%', height: '100px',background: '#ccccff', background: '#ccccff', alignItems: 'center', display: 'grid', fontSize: '14px' }}>
                        {/* <div className="d-flex justify-content-between align-items-center"> */}
                        <div
                          className=""
                          style={{
                            display: "flex",
                            
                            alignItems: "center",
                          }}
                        >
                          <div className="paddigery-text py-2">
                            {pedigree?.pedigree?.gen3[0]?.ggfather1.length ===
                            0 ? (
                              <h5>Unknown</h5>
                            ) : (
                              pedigree?.pedigree?.gen3[0]?.ggfather1.map(
                                (ggfather1Data, i) => (
                                  <h6 className="font-weight-bold" key={i}>
                                    {ggfather1Data.horse_name}
                                  </h6>
                                )
                              )
                            )}
                            {pedigree?.pedigree?.gen3[0]?.ggfather1[0]?.dob != null ? 
                            <h6 className="font-weight-bold" >
                              {'b. '+pedigree?.pedigree?.gen3[0]?.ggfather1[0]?.dob}
                            </h6>
                            :
                            ''
                            }
                            {pedigree?.pedigree?.gen3[0]?.ggfather1[0]?.regestration_no != null ? 
                            <h6 className="font-weight-bold" >
                              {pedigree?.pedigree?.gen3[0]?.ggfather1[0]?.regestration_no}
                            </h6>
                            :
                            ''
                            }
                          </div>
                        </div>
                      </span>

                      {/* //sub entery */}

                      <div className="branch">
                        <div className="entry">
                          <span style={{ height: '50px', top: '184%', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggfather1.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggfather1.map(
                                    (gggfather1Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggfather1Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggfather1[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggfather1[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggfather1[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggfather1[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                            <div className="branch">
                              <div className="entry">
                                <span style={{ top: '368%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather1?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather1[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '303%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother1?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother1[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                        </div>
                        <div className="entry">
                          <span style={{ height: '50px', top: '120%', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggmother1.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggmother1.map(
                                    (gggmother1Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggmother1Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggmother1[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggmother1[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggmother1[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggmother1[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '239%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather2?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather2[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '175%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother2?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother2[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="entry">
                      <span style={{ top: '28%', height: '100px', background: '#ffcccc', alignItems: 'center', display: 'grid', fontSize: '14px' }}>
                        {/* <div className="d-flex justify-content-between align-items-center"> */}
                        <div
                          className=""
                          style={{
                            display: "flex",
                            
                            alignItems: "center",
                          }}
                        >
                          <div className="paddigery-text py-2">
                            {pedigree?.pedigree?.gen3[0]?.ggmother1.length ===
                            0 ? (
                              <h5>Unknown</h5>
                            ) : (
                              pedigree?.pedigree?.gen3[0]?.ggmother1.map(
                                (ggmother1Data, i) => (
                                  <h6 className="font-weight-bold" key={i}>
                                    {ggmother1Data.horse_name}
                                  </h6>
                                )
                              )
                            )}
                            {pedigree?.pedigree?.gen3[0]?.ggmother1[0]?.dob != null ? 
                            <h6 className="font-weight-bold" >
                              {'b. '+pedigree?.pedigree?.gen3[0]?.ggmother1[0]?.dob}
                            </h6>
                            :
                            ''
                            }
                            {pedigree?.pedigree?.gen3[0]?.ggmother1[0]?.regestration_no != null ? 
                            <h6 className="font-weight-bold" >
                              {pedigree?.pedigree?.gen3[0]?.ggmother1[0]?.regestration_no}
                            </h6>
                            :
                            ''
                            }
                          </div>
                        </div>
                      </span>
                      {/* //sub entery */}

                      <div className="branch">
                        <div className="entry">
                          <span style={{ top: '55%', height: '50px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggfather2.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggfather2.map(
                                    (gggfather2Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggfather2Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggfather2[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggfather2[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggfather2[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggfather2[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '112%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5[0]?.ggggfather3?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather3[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '48%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother3[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother3[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span style={{ top: '-9%', height: '50px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggmother2.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggmother2.map(
                                    (gggmother2Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggmother2Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggmother2[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggmother2[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggmother2[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggmother2[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-15%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather4?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather4[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-80%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother4?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother4[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="entry" >
                  <span style={{ top: '-13%', marginTop: '-45px', height: '200px', background: '#ffcccc', alignItems: 'center', display: 'grid', fontSize: '16px' }}>
                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                    <div
                      className=""
                      style={{
                        display: "flex",
                        
                        alignItems: "center",
                      }}
                    >
                      <div className="paddigery-text py-2">
                        {/* {padigree?.fatherMother ? padigree?.fatherMother?.slice(0, 13) + "...." : "Unknown"} */}
                        {pedigree?.pedigree?.gen2[0]?.gmother1.length === 0 ? (
                          <h5>Unknown</h5>
                        ) : (
                          pedigree?.pedigree?.gen2[0]?.gmother1.map(
                            (gmother1Data, i) => (
                              <h6 className="" key={i}>
                                {gmother1Data.horse_name}
                              </h6>
                            )
                          )
                        )}
                        {pedigree?.pedigree?.gen2[0]?.gmother1[0]?.dob != null ? 
                        <h6 className="font-weight-bold" >
                          {'b. '+pedigree?.pedigree?.gen2[0]?.gmother1[0]?.dob}
                        </h6>
                        :
                        ''
                        }
                        {pedigree?.pedigree?.gen2[0]?.gmother1[0]?.regestration_no != null ? 
                        <h6 className="font-weight-bold" >
                          {pedigree?.pedigree?.gen2[0]?.gmother1[0]?.regestration_no}
                        </h6>
                        :
                        ''
                        }
                      </div>
                    </div>
                  </span>

                  <div className="branch">
                    <div className="entry">
                      <span style={{top: '-36%', height: '100px', background: '#ccccff', alignItems: 'center', display: 'grid', fontSize: '14px'}}>
                        {/* <div className="d-flex justify-content-between align-items-center"> */}
                        <div
                          className=""
                          style={{
                            display: "flex",
                            
                            alignItems: "center",
                          }}
                        >
                          <div className="paddigery-text py-2">
                            {pedigree?.pedigree?.gen3[0]?.ggfather2.length ===
                            0 ? (
                              <h5>Unknown</h5>
                            ) : (
                              pedigree?.pedigree?.gen3[0]?.ggfather2.map(
                                (ggfather2Data, i) => (
                                  <h6 className="font-weight-bold" key={i}>
                                    {ggfather2Data.horse_name}
                                  </h6>
                                )
                              )
                            )}
                            {pedigree?.pedigree?.gen3[0]?.ggfather2[0]?.dob != null ? 
                            <h6 className="font-weight-bold" >
                              {'b. '+pedigree?.pedigree?.gen3[0]?.ggfather2[0]?.dob}
                            </h6>
                            :
                            ''
                            }
                            {pedigree?.pedigree?.gen3[0]?.ggfather2[0]?.regestration_no != null ? 
                            <h6 className="font-weight-bold" >
                              {pedigree?.pedigree?.gen3[0]?.ggfather2[0]?.regestration_no}
                            </h6>
                            :
                            ''
                            }
                          </div>
                        </div>
                      </span>

                      {/* //sub entery */}

                      <div className="branch">
                        <div className="entry">
                          <span style={{ height: '50px', background: '#ccccff', top: '-73%', marginTop: '-15px', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggfather3.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggfather3.map(
                                    (gggfather3Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggfather3Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggfather3[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggfather3[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggfather3[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggfather3[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-144%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather5?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather5[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-208%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother5?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother5[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                        </div>
                        <div className="entry">
                          <span style={{ height: '50px', top: '-137%', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggmother3.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggmother3.map(
                                    (gggmother3Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggmother3Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggmother3[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggmother3[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggmother3[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggmother3[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-271%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather6?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather6[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-336%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother6?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother6[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="entry">
                      <span style={{top: '-100%', height: '100px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '14px'}}>
                        {/* <div className="d-flex justify-content-between align-items-center"> */}
                        <div
                          className=""
                          style={{
                            display: "flex",
                            
                            alignItems: "center",
                          }}
                        >
                          <div className="paddigery-text py-2">
                            {pedigree?.pedigree?.gen3[0]?.ggmother2.length ===
                            0 ? (
                              <h5>Unknown</h5>
                            ) : (
                              pedigree?.pedigree?.gen3[0]?.ggmother2.map(
                                (ggmother2Data, i) => (
                                  <h6 className="font-weight-bold" key={i}>
                                    {ggmother2Data.horse_name}
                                  </h6>
                                )
                              )
                            )}
                            {pedigree?.pedigree?.gen3[0]?.ggmother2[0]?.dob != null ? 
                            <h6 className="font-weight-bold" >
                              {'b. '+pedigree?.pedigree?.gen3[0]?.ggmother2[0]?.dob}
                            </h6>
                            :
                            ''
                            }
                            {pedigree?.pedigree?.gen3[0]?.ggmother2[0]?.regestration_no != null ? 
                            <h6 className="font-weight-bold" >
                              {pedigree?.pedigree?.gen3[0]?.ggmother2[0]?.regestration_no}
                            </h6>
                            :
                            ''
                            }
                          </div>
                        </div>
                      </span>
                      {/* //sub entery */}

                      <div className="branch">
                        <div className="entry">
                          <span style={{ height: '50px', top: '-200%', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggfather4.length ===
                                0 ? (
                                  <h5 >Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggfather4.map(
                                    (gggfather4Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggfather4Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggfather4[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggfather4[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggfather4[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggfather4[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-400%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather7?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather7?.[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-465%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother7?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother7?.[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                        </div>
                        <div className="entry">
                          <span style={{ top: '-264%', height: '50px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggmother4.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggmother4.map(
                                    (gggmother4Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggmother4Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggmother4[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggmother4[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggmother4[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggmother4[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-529%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather8?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather8[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-593%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother8?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother8[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="entry">
              <span id="gen1" style={{top: '-40%', marginTop: '-29px', height: '400px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '16px' }}>
                <div>
                  <div className="paddigery-text py-2">
                    {/* {padigree?.mother ? padigree?.mother?.slice(0, 20) + "...." : "Unkown"} */}
                    {pedigree?.pedigree?.gen1[0]?.mother.length === 0 ? (
                      <h5>Unknown</h5>
                    ) : (
                      pedigree?.pedigree?.gen1[0]?.mother.map((montherData, i) => (
                        <h6 className="font-weight-bold" key={i}>
                          {montherData.horse_name}
                        </h6>
                      ))
                    )}
                  </div>
                </div>
              </span>
              <div className="branch">
                <div className="entry" style={{ background: "" }}>
                  <span style={{ top: '-80%', marginTop: '-29px', height: '200px', background: 'rgb(204 204 255)', alignItems: 'center', display: 'grid', fontSize: '16px' }}>
                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                    <div
                      className=""
                      style={{
                        display: "flex",
                        
                        alignItems: "center",
                      }}
                    >
                      <div className="paddigery-text py-2">
                        {/* {padigree?.MotherFather ? padigree?.MotherFather?.slice(0, 13) + "...." : "Unknown"} */}
                        {pedigree?.pedigree?.gen2[0]?.gfather2.length === 0 ? (
                          <h5>Unknown</h5>
                        ) : (
                          pedigree?.pedigree?.gen2[0]?.gfather2.map(
                            (gfather2Data, i) => (
                              <h6 className="" key={i}>
                                {gfather2Data.horse_name}
                              </h6>
                            )
                          )
                        )}
                        {pedigree?.pedigree?.gen2[0]?.gfather2[0]?.dob != null ? 
                        <h6 className="font-weight-bold" >
                          {'b. '+pedigree?.pedigree?.gen2[0]?.gfather2[0]?.dob}
                        </h6>
                        :
                        ''
                        }
                        {pedigree?.pedigree?.gen2[0]?.gfather2[0]?.regestration_no != null ? 
                        <h6 className="font-weight-bold" >
                          {pedigree?.pedigree?.gen2[0]?.gfather2[0]?.regestration_no}
                        </h6>
                        :
                        ''
                        }
                      </div>
                    </div>
                  </span>
                  <div className="branch">
                    <div className="entry" >
                      <span style={{ top: '-164%', height: '100px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '14px' }}>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            
                            alignItems: "center",
                          }}
                        >
                          <div className="paddigery-text py-2">
                            {pedigree?.pedigree?.gen3[0]?.ggfather3.length ===
                            0 ? (
                              <h5>Unknown</h5>
                            ) : (
                              pedigree?.pedigree?.gen3[0]?.ggfather3.map(
                                (ggfather3Data, i) => (
                                  <h6 className="" key={i}>
                                    {ggfather3Data.horse_name}
                                  </h6>
                                )
                              )
                            )}
                            {pedigree?.pedigree?.gen3[0]?.ggfather3[0]?.dob != null ? 
                            <h6 className="font-weight-bold" >
                              {'b. '+pedigree?.pedigree?.gen3[0]?.ggfather3[0]?.dob}
                            </h6>
                            :
                            ''
                            }
                            {pedigree?.pedigree?.gen3[0]?.ggfather3[0]?.regestration_no != null ? 
                            <h6 className="font-weight-bold" >
                              {pedigree?.pedigree?.gen3[0]?.ggfather3[0]?.regestration_no}
                            </h6>
                            :
                            ''
                            }
                          </div>
                        </div>
                      </span>
                      {/* //sub entery */}

                      <div className="branch">
                        <div className="entry">
                          <span style={{ height: '50px', top: '-328%', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggfather5.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggfather5.map(
                                    (gggfather5Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggfather5Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggfather5[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggfather5[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggfather5[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggfather5[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-657%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather9?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather9[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-721%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother9?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother9[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span style={{ top: '-391%', height: '50px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggmother5.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggmother5.map(
                                    (gggmother5Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggmother5Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggmother5[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggmother5[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggmother5[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggmother5[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-781%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather10?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather10[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-845%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother10?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother10[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="entry" >
                      <span style={{ top: '-226%', marginTop: '-21px', height: '100px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '14px' }}>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            
                            alignItems: "center",
                          }}
                        >
                          <div className="paddigery-text py-2">
                            {pedigree?.pedigree?.gen3[0]?.ggmother3.length ===
                            0 ? (
                              <h5>Unknown</h5>
                            ) : (
                              pedigree?.pedigree?.gen3[0]?.ggmother3.map(
                                (ggmother3Data, i) => (
                                  <h6 className="" key={i}>
                                    {ggmother3Data.horse_name}
                                  </h6>
                                )
                              )
                            )}
                            {pedigree?.pedigree?.gen3[0]?.ggmother3[0]?.dob != null ? 
                            <h6 className="font-weight-bold" >
                              {'b. '+pedigree?.pedigree?.gen3[0]?.ggmother3[0]?.dob}
                            </h6>
                            :
                            ''
                            }
                            {pedigree?.pedigree?.gen3[0]?.ggmother3[0]?.regestration_no != null ? 
                            <h6 className="font-weight-bold" >
                              {pedigree?.pedigree?.gen3[0]?.ggmother3[0]?.regestration_no}
                            </h6>
                            :
                            ''
                            }
                          </div>
                        </div>
                      </span>

                      {/* //sub entery */}

                      <div className="branch">
                        <div className="entry">
                          <span style={{ height: '50px', top: '-455%', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggfather6.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggfather6.map(
                                    (gggfather6Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggfather6Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggfather6[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggfather6[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggfather6[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggfather6[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-909%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather11?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather11[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-972%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother11?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother11[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span style={{ height: '50px', top: '-519%', background: 'rgb(255, 204, 204)', display: 'grid', alignItems: 'center', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggmother6.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggmother6.map(
                                    (gggmother6Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggmother6Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggmother6[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggmother6[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggmother6[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggmother6[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-1036%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather12?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather12[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-1099%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother12?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother12[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="entry" style={{ background: "" }}>
                  <span style={{ top: '-135%', marginTop: '-80px', height: '200px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '16px' }}>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        
                        alignItems: "center",
                      }}
                    >
                      <div className="paddigery-text py-2">
                        {/* {padigree?.motherMother ? padigree?.motherMother?.slice(0, 13) + "...." :"Unkown"} */}
                        {pedigree?.pedigree?.gen2[0]?.gmother2.length === 0 ? (
                          <h5>Unknown</h5>
                        ) : (
                          pedigree?.pedigree?.gen2[0]?.gmother2.map(
                            (gmother2Data, i) => (
                              <h6 className="" key={i}>
                                {gmother2Data.horse_name}
                              </h6>
                            )
                          )
                        )}
                        {pedigree?.pedigree?.gen2[0]?.gmother2[0]?.dob != null ? 
                        <h6 className="font-weight-bold" >
                          {'b. '+pedigree?.pedigree?.gen2[0]?.gmother2[0]?.dob}
                        </h6>
                        :
                        ''
                        }
                        {pedigree?.pedigree?.gen2[0]?.gmother2[0]?.regestration_no != null ? 
                        <h6 className="font-weight-bold" >
                          {pedigree?.pedigree?.gen2[0]?.gmother2[0]?.regestration_no}
                        </h6>
                        :
                        ''
                        }
                      </div>
                    </div>
                  </span>
                  <div className="branch">
                    <div className="entry" >
                      <span style={{ top: '-292%', height: '100px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '14px' }}>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            
                            alignItems: "center",
                          }}
                        >
                          <div className="paddigery-text py-2">
                            {pedigree?.pedigree?.gen3[0]?.ggfather4.length ===
                            0 ? (
                              <h5>Unknown</h5>
                            ) : (
                              pedigree?.pedigree?.gen3[0]?.ggfather4.map(
                                (ggfather4Data, i) => (
                                  <h6 className="" key={i}>
                                    {ggfather4Data.horse_name}
                                  </h6>
                                )
                              )
                            )}
                            {pedigree?.pedigree?.gen3[0]?.ggfather4[0]?.dob != null ? 
                            <h6 className="font-weight-bold" >
                              {'b. '+pedigree?.pedigree?.gen3[0]?.ggfather4[0]?.dob}
                            </h6>
                            :
                            ''
                            }
                            {pedigree?.pedigree?.gen3[0]?.ggfather4[0]?.regestration_no != null ? 
                            <h6 className="font-weight-bold" >
                              {pedigree?.pedigree?.gen3[0]?.ggfather4[0]?.regestration_no}
                            </h6>
                            :
                            ''
                            }
                          </div>
                        </div>
                      </span>

                      {/* //sub entery */}

                      <div className="branch">
                        <div className="entry">
                          <span style={{ height: '50px', top: '-583%', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggfather7.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggfather7.map(
                                    (gggfather7Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggfather7Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggfather7[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggfather7[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggfather7[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggfather7[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-1163%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather13?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0} style={{padding: 0, margin: 0 }}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather13[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-1227%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother13?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0} style={{padding: 0, margin: 0 }}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother13[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span style={{ height: '50px', top: '-648%', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggmother7.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggmother7.map(
                                    (gggmother7Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggmother7Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggmother7[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggmother7[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggmother7[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggmother7[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-1291%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather14?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather14[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-1355%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother14?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother14[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="entry">
                      <span style={{ height: '100px', top: '-356%', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '14px' }}>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            
                            alignItems: "center",
                          }}
                        >
                          <div className="paddigery-text py-2">
                            {pedigree?.pedigree?.gen3[0]?.ggmother4.length ===
                            0 ? (
                              <h5>Unknown</h5>
                            ) : (
                              pedigree?.pedigree?.gen3[0]?.ggmother4.map(
                                (ggmother4Data, i) => (
                                  <h6 className="" key={i}>
                                    {ggmother4Data.horse_name}
                                  </h6>
                                )
                              )
                            )}
                            {pedigree?.pedigree?.gen3[0]?.ggmother4[0]?.dob != null ? 
                            <h6 className="font-weight-bold" >
                              {'b. '+pedigree?.pedigree?.gen3[0]?.ggmother4[0]?.dob}
                            </h6>
                            :
                            ''
                            }
                            {pedigree?.pedigree?.gen3[0]?.ggmother4[0]?.regestration_no != null ? 
                            <h6 className="font-weight-bold" >
                              {pedigree?.pedigree?.gen3[0]?.ggmother4[0]?.regestration_no}
                            </h6>
                            :
                            ''
                            }
                          </div>
                        </div>
                      </span>

                      {/* //sub entery */}

                      <div className="branch">
                        <div className="entry">
                          <span style={{ top: '-712%', height: '50px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggfather8.length ===
                                0 ? (
                                  <h5>Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggfather8.map(
                                    (gggfather8Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggfather8Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggfather8[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggfather8[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggfather8[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggfather8[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-1418%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather15?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather15[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-1481%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother15?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother15[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                          </div>
                        </div>
                        <div className="entry">
                          <span style={{ height: '50px', top: '-776%', background: 'rgb(255, 204, 204)', display: 'grid', alignItems: 'center', fontSize: '13px' }}>
                            {/* <div className="d-flex justify-content-between align-items-center"> */}
                            <div
                              className=""
                              style={{
                                display: "flex",
                                
                                alignItems: "center",
                              }}
                            >
                              <div className="paddigery-text py-2">
                                {pedigree?.pedigree?.gen4[0]?.gggmother8.length ===
                                0 ? (
                                  <h5 >Unknown</h5>
                                ) : (
                                  pedigree?.pedigree?.gen4[0]?.gggmother8.map(
                                    (gggmother8Data, i) => (
                                      <h6 className="font-weight-bold" key={i}>
                                        {gggmother8Data.horse_name}
                                      </h6>
                                    )
                                  )
                                )}
                                {pedigree?.pedigree?.gen4[0]?.gggmother8[0]?.dob != null ? 
                                <h6 className="font-weight-bold" >
                                  {'b. '+pedigree?.pedigree?.gen4[0]?.gggmother8[0]?.dob}
                                </h6>
                                :
                                ''
                                }
                                {pedigree?.pedigree?.gen4[0]?.gggmother8[0]?.regestration_no != null ? 
                                <h6 className="font-weight-bold" >
                                  {pedigree?.pedigree?.gen4[0]?.gggmother8[0]?.regestration_no}
                                </h6>
                                :
                                ''
                                }
                              </div>
                            </div>
                          </span>
                          {/* Gen 5 */}
                          <div className="branch">
                              <div className="entry">
                                <span style={{ top: '-1545%', height: '25px', background: 'rgb(204, 204, 255)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggfather16?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggfather16[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="entry">
                                <span style={{ top: '-1610%', height: '25px', background: 'rgb(255, 204, 204)', alignItems: 'center', display: 'grid', fontSize: '13px' }}>
                                  {/* <div className="d-flex justify-content-between align-items-center"> */}
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="paddigery-text py-2">
                                      {pedigree?.pedigree?.gen5?.[0]?.ggggmother16?.[0]?.horse_name == null ?
                                        <h5 style={{padding: 0, margin: 0 }}>Unknown</h5>
                                       :
                                        <h6 className="font-weight-bold" key={0}>
                                          {pedigree?.pedigree?.gen5[0]?.ggggmother16[0]?.horse_name}
                                        </h6>
                                       }
                                    </div>
                                  </div>
                                </span>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PedigreeModal;
