import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AspectRatio from "@mui/joy/AspectRatio";
import classes from "./MemberList.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

function Items({ currentItems, query, resetSearchQuery }) {
  const navigate = useNavigate();
  // console.log("CurrentItems: ", currentItems);
  const breedDetailNavigateHandler = (name) => {
    if (name !== null && name !== "null") {
      navigate(`${name}`);
    }
  };

  // const filteredItems = currentItems.filter(
  //   (item) =>
  //     item.first_name.toLowerCase().includes(query.toLowerCase()) ||
  //     item.last_name.toLowerCase().includes(query.toLowerCase())
  // );

  // console.log("Query", query);
  const filteredItems = currentItems.filter(
    (item) =>
      item.first_name.toLowerCase().includes(query.toLowerCase()) ||
      item.last_name.toLowerCase().includes(query.toLowerCase())
  );

  const itemsToDisplay = query !== "" ? filteredItems : currentItems;

  return (
    <>
      {query !== "" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="primary"
            style={{
              color: "#0f2d25",
              margin: 0,
            }}
            onClick={resetSearchQuery}
          >
            View All Members
          </Button>
        </div>
      ) : (
        <></>
      )}
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="center"
      >
        {/* {currentItems &&
          currentItems.map((item, index) => ( */}

        {/* query != "" && (query === item.first_name || query === item.last_name) */}

        {itemsToDisplay.map((item, index) => (
          <Grid item xs={6} sm={4} md={4} key={item.username}>
            <div
              onClick={() => {
                breedDetailNavigateHandler(item.friendly_url);
              }}
              style={{
                cursor: "pointer",
                textAlign: "center",
                marginBottom: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                marginTop: "20px",
              }}
            >
              {item.photo !== "" && item.photo !== null ? (
                <>
                  <AspectRatio
                    ratio="1"
                    sx={{
                      width: 120,
                      top: "-0.938rem",
                      marginRight: "-2.1rem",
                      zIndex: "1",
                      "@media (max-width: 600px)": {
                        marginRight: "0.1rem",
                        width: "70%",
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                    style={{ borderRadius: "50%" }}
                  >
                    <img
                      src={
                        "https://admin.arabians.pk/public/members/profile_pic/" +
                        item.photo
                      }
                      alt="https://admin.arabians.pk/public/img/blank.jpg"
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </AspectRatio>
                  <Card
                    sx={{
                      width: "100%",
                      maxWidth: "100%",
                      boxShadow: "lg",
                      display: "flex",
                      flexDirection: "row", // Default flex direction: row
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "30px",
                      // borderTopRightRadius: "100%",
                      // borderBottomRightRadius: "100%",
                      padding: "3px",
                      "@media (max-width: 600px)": {
                        width: "100%",
                        flexDirection: "column", // Flex direction on mobile: column
                        alignItems: "center",
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        "@media (max-width: 600px)": {
                          flex: 2,
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Typography
                        level="title-lg"
                        id="card-description"
                        style={{
                          color: "#217a77",
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {item.first_name} {item.last_name}
                      </Typography>
                      <Typography
                        level="title-lg"
                        style={{
                          color: "#314d46",
                          opacity: 0.7,
                          marginTop: "8px",
                        }}
                      >
                        Membership#: {item.membership_no}
                      </Typography>
                    </CardContent>
                  </Card>
                </>
              ) : (
                <>
                  <AspectRatio
                    ratio="1"
                    sx={{
                      width: 130,
                      top: "-1rem",
                      marginRight: "-2rem",
                      zIndex: "1",
                      "@media (max-width: 600px)": {
                        marginRight: "-0.1rem",
                        width: "50%",
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                    style={{ borderRadius: "50%" }}
                  >
                    <img
                      src={"https://admin.arabians.pk/public/img/blank.jpg"}
                      alt="https://admin.arabians.pk/public/img/blank.jpg"
                      loading="lazy"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </AspectRatio>
                  <Card
                    sx={{
                      width: "100%", // Set width to 100% for responsiveness
                      maxWidth: 360, // Set max-width if needed
                      boxShadow: "lg",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "30px",
                      padding: "3px",
                      "@media (max-width: 600px)": {
                        flexDirection: "column",
                        alignItems: "center",
                      },
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        padding: "1.2rem",
                        "@media (max-width: 600px)": {
                          flex: 2,
                          padding: "8px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Typography
                        level="title-lg"
                        id="card-description"
                        style={{
                          color: "#217a77",
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {item.first_name} {item.last_name}
                      </Typography>
                      <Typography
                        level="title-lg"
                        style={{
                          color: "#314d46",
                          opacity: 0.7,
                          marginTop: "8px",
                        }}
                      >
                        Membership#: {item.membership_no}
                      </Typography>
                    </CardContent>
                  </Card>
                </>
              )}
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function PaginatedItems({ itemsPerPage, data, query, resetSearchQuery }) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const filteredItems = data.filter(
    (item) =>
      (item.first_name &&
        item.first_name.toLowerCase().includes(query.toLowerCase())) ||
      (item.last_name &&
        item.last_name.toLowerCase().includes(query.toLowerCase()))
  );

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(
      Math.ceil(
        (query !== "" ? filteredItems.length : data.length) / itemsPerPage
      )
    );
  }, [itemOffset, itemsPerPage, data, filteredItems.length, query]);

  // useEffect(() => {
  //   const endOffset = itemOffset + itemsPerPage;
  //   console.log(data);
  //   setCurrentItems(data.slice(itemOffset, endOffset));
  //   setPageCount(Math.ceil(data.length / itemsPerPage));
  // }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <div className={classes["breed-list"]}>
      <Items
        currentItems={query !== "" ? filteredItems : currentItems}
        query={query}
        resetSearchQuery={resetSearchQuery}
      />

      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <KeyboardArrowRightIcon
            sx={{
              fontSize: "25px",
            }}
          />
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={
          <KeyboardArrowLeftIcon
            sx={{
              fontSize: "25px",
            }}
          />
        }
        renderOnZeroPageCount={null}
      />
    </div>
  );
}

export default PaginatedItems;
